import { Button, TextField, Tooltip } from "@mui/material";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { SetLabwareType } from "features/WorklistTools/shared/components/SetLabwareType";
import { SourcePlate } from "features/WorklistTools/shared/components/SourcePlate";
import "features/WorklistTools/shared/components/styles/WorklistGenerator.scss";
import {
  UploadedSourcePlate,
  UploadSourcePlateDialog,
} from "features/WorklistTools/shared/components/UploadSourcePlateDialog";
import { useCtrlDown } from "features/WorklistTools/shared/hooks/hooks";
import {
  PlatePosition,
  ReactSelectableFast,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import { sortByCol } from "features/WorklistTools/shared/WorklistHelpers";
import {
  GetLabwareTypesQuery,
  LookupLabwareType,
  useGetPlatesInfoLazyQuery,
  useSetLabwareTypeMutation,
} from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useState } from "react";
import { SelectableGroup } from "react-selectable-fast";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import styled from "styled-components";
import { DiscardSourcePlate } from "../../shared/components/DiscardSourcePlate";
import { SourcePlateInfoState, Twelve24TCToolActions } from "../state";
import { Twelve24TCToolInternalAction } from "../state/action";
import { useHandleMethodSettingsUpdated } from "../state/handlers";
import { useTwelve24TCStateProvider } from "../state/StateProvider";
import { handleUploadPlate } from "./handlers/HandleUploadPlate";
import {
  disablePlateUploadTooltipComponent,
  disableSourcePlateUploadBool,
  disableSourcePlateUploadButtonMsg,
} from "./handlers/uploadPlateButtonDisabled";
const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%;
`;

const StyledPlateTypeContainer = styled.div`
  margin-top: 15px;
`;

const StyledPlateActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const StyledDiscardPlate = styled.div`
  width: 100%;
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
`;

export const SourcePlatesContainer = (sourcePlateTypeInfo: any) => {
  const { isConfirmed } = useConfirm();
  const [selectedPlateIndex, setSelectedPlateIndex] = useState(0);
  const [uploadPlateDialogOpen, setUploadPlateDialogOpen] = useState(false);
  const [labwareTypeDialogOpen, setLabwareTypeDialogOpen] = useState(false);
  const { twelve24TCToolInternalState, twelve24TCToolDispatch } =
    useTwelve24TCStateProvider();

  const dispatch = useAppReduxDispatch();
  const [colorIndex, setColorIndex] = useState(0);
  const updateMethodSettings = useHandleMethodSettingsUpdated();

  const handleMethodSettingsChanged = async (key: string, value: any) => {
    updateMethodSettings(key, parseInt(value));
  };

  const [getPlateInfo, { data: plateInfo, refetch }] =
    useGetPlatesInfoLazyQuery({
      onCompleted: (data) => {
        handleUploadPlate({
          data,
          setLabwareTypeDialogOpen,
          uploadIntermediatePlate: false,
          sourcePlateInfo,
          dispatch,
          colorIndex,
          setColorIndex,
          selectedPlateIndex,
        });
      },
    });
  const [setLabwareType] = useSetLabwareTypeMutation();
  const sourcePlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.sourcePlateInfo
  );
  const handleUploadSourcePlateClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    plate: UploadedSourcePlate | undefined,
    data: GetLabwareTypesQuery | undefined
  ) => {
    setUploadPlateDialogOpen(false);
    getPlateInfo({
      variables: {
        where: {
          plateCreatedId: { eq: plate!.plateCreatedId },
          containers: {
            some: {
              isActive: { eq: true },
            },
          },
        },
      },
    });
  };

  const handleSourceWellSelection = (value: ReactSelectableFast[]) => {
    const wellSelection = value?.map((well) => ({
      plateIndex: well.props.plateIndex,
      plateWellId: well.props.plateWellId,
      isSelectable: !well.node.classList.contains("not-selectable"),
    }));
    sortByCol(wellSelection);
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.SET_SELECTED_SOURCE_WELLS,
      payload: {
        selectedSourceWells: wellSelection,
      },
    });
  };

  const handleSetLabwareTypeClose = (
    plateId: number,
    labwareType: LookupLabwareType | undefined,
    saveChanges: boolean
  ) => {
    setLabwareTypeDialogOpen(false);
    if (saveChanges) {
      const labwareTypeId = labwareType!.labwareTypeId;
      setLabwareType({
        variables: {
          plateId,
          labwareTypeId,
        },
      }).then(() => {
        refetch!().then((refetchData) => {
          handleUploadPlate({
            data: refetchData.data,
            setLabwareTypeDialogOpen,
            uploadIntermediatePlate: false,
            sourcePlateInfo,
            dispatch,
            colorIndex,
            setColorIndex,
            selectedPlateIndex,
          });
        });
      });
    }
  };

  const handleUploadPlateClick = (index: number) => {
    setUploadPlateDialogOpen(true);
    setSelectedPlateIndex(index);
  };

  const handleRemovePlateClick = async (index: number) => {
    const confirmed = await isConfirmed(
      "Are you sure you want to remove this plate? NOTE: You can't add the plate back"
    );

    if (!confirmed) {
      return;
    }
    dispatch(
      Twelve24TCToolActions.CLEAR_SOURCE_PLATE({
        index,
      })
    );
  };

  const updateDiscardSourcePlate = (index: number, value: number) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DISCARD_SOURCE_PLATE({
        index,
        value,
      })
    );
  };

  const disableUploadButtonSrc = (plateIndex: number) => {
    return sourcePlateInfo[plateIndex].plateBarcode !== "";
  };

  const ctrlDown = useCtrlDown();

  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: "600px",
        overflow: "auto",
      }}
    >
      {sourcePlateInfo.map((item: SourcePlateInfoState, index: number) => (
        <div style={{ padding: "20px" }}>
          <React.Fragment>
            <TextField
              variant="outlined"
              style={{ width: "100%", marginBottom: "10px" }}
              disabled
              label="Source Plate Barcode"
              value={item.plateBarcode}
              InputProps={{
                startAdornment: <b>{index + 1}| </b>,
              }}
            />

            <React.Fragment>
              <div>
                <StyledSelectableGroup
                  onSelectionFinish={handleSourceWellSelection}
                  resetOnStart={
                    ctrlDown.worklistToolInternalState.setCtrlDown
                      ? false
                      : true
                  }
                  allowCtrlClick={true}
                >
                  <SourcePlate
                    worklistTool={WorklistTools.Twelve24TC}
                    index={index}
                    wellInfo={item.wellInfo}
                    plateRows={item.rows}
                    plateCols={item.cols}
                  />
                </StyledSelectableGroup>
              </div>
              <StyledPlateActions>
                <Tooltip
                  title={disablePlateUploadTooltipComponent(
                    twelve24TCToolInternalState,
                    disableSourcePlateUploadButtonMsg(
                      twelve24TCToolInternalState
                    )
                  )}
                  disableHoverListener={
                    !disableSourcePlateUploadBool(twelve24TCToolInternalState)
                  }
                >
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ width: "100%" }}
                      variant="outlined"
                      onClick={() => handleUploadPlateClick(index)}
                      disabled={
                        disableSourcePlateUploadBool(
                          twelve24TCToolInternalState
                        ) || disableUploadButtonSrc(index)
                      }
                    >
                      Upload Plate
                    </Button>
                  </div>
                </Tooltip>

                <Button
                  variant="outlined"
                  disabled={item.rows.length < 1}
                  onClick={() => handleRemovePlateClick(index)}
                >
                  Remove Plate
                </Button>
              </StyledPlateActions>

              <StyledDiscardPlate>
                <DiscardSourcePlate
                  index={index}
                  item={item}
                  updateDiscardSourcePlate={updateDiscardSourcePlate}
                ></DiscardSourcePlate>
              </StyledDiscardPlate>

              {item.rows.length > 0 && item.cols.length > 0 ? (
                <StyledPlateTypeContainer>
                  <PlateType
                    platePosition={PlatePosition.Source}
                    isDisabled={
                      index > 0 && sourcePlateInfo[index - 1].rows.length === 0
                    }
                    plateType={item.labwareTypeCode}
                    acceptedPlateTypes={sourcePlateTypeInfo.sourcePlateInfo}
                  />
                </StyledPlateTypeContainer>
              ) : null}
            </React.Fragment>
          </React.Fragment>
        </div>
      ))}

      <UploadSourcePlateDialog
        isOpen={uploadPlateDialogOpen}
        handleClose={() => setUploadPlateDialogOpen(false)}
        handleUpload={handleUploadSourcePlateClose}
        queryVariables={
          sourcePlateTypeInfo.sourcePlateInfo
            ? {
                where: {
                  labwareTypeId: {
                    in: sourcePlateTypeInfo.sourcePlateInfo.map((e: any) => {
                      return e.labwareTypeId;
                    }),
                  },
                  plateBarcode: {
                    nin: sourcePlateInfo.map((item) => item.plateBarcode),
                  },
                },
              }
            : {}
        }
        title="Select a Source Plate to Upload"
      ></UploadSourcePlateDialog>

      <SetLabwareType
        isOpen={labwareTypeDialogOpen}
        handleClose={(plateId, labwareType, saveChanges) =>
          handleSetLabwareTypeClose(plateId, labwareType, saveChanges)
        }
        plateId={plateInfo?.platesInfo?.plateCreatedId ?? 0}
        plateBarcode={plateInfo?.platesInfo?.plateBarcode ?? ""}
        plateLayoutTypeId={
          plateInfo?.platesInfo?.arrayPlateType?.plateLayoutTypeId ?? 0
        }
      ></SetLabwareType>
      <ConfirmDialog />
    </div>
  );
};
