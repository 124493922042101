import { ManagePersonalTask } from "graphql/generated/schema-types";
import { ManagePersonalTaskInputMapper } from "mappings/managePersonalTaskInput";
import React from "react";
import {
  DateInput,
  RescheduleTaskDialog,
} from "shared-components/Reschedule/RescheduleTaskDialog";
import { AssignToGroupsDialog } from "./AssignToGroup";
import { AssignToSystemDialog } from "./AssignToSystem";
import { AssignToUsersDialog } from "./AssignToUser";
import { EditTaskDialog } from "./EditTaskDialog";

export enum ContextMenuDialogs {
  AssignToUser,
  AssignToGroup,
  AssignToSystem,
  RescheduleTask,
  EditTask,
  ViewTaskHistory,
}

export function ContextMenuDialogSelector({
  dialog,
  selectedTasks,
  isOpen,
  setState,
  refetch,
  variables,
  updatePersonalTask,
  setPaginationState,
}: {
  dialog: ContextMenuDialogs;
  selectedTasks: ManagePersonalTask[] | undefined;
  isOpen: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: any;
  variables: any;
  updatePersonalTask: any;
  setPaginationState: any;
}): JSX.Element {
  return (
    <>
      {ContextMenus(
        dialog,
        selectedTasks,
        isOpen,
        setState,
        refetch,
        variables,
        updatePersonalTask,
        setPaginationState
      )}
    </>
  );
}

function ContextMenus(
  dialog: ContextMenuDialogs,
  selectedTasks: ManagePersonalTask[] | undefined,
  isOpen: boolean,
  setState: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: any,
  variables: any,
  updatePersonalTask: any,
  setPaginationState: any
): JSX.Element {
  const updateSelectedPersonalTask = (
    field: any,
    newValue: any,
    updateTask: boolean
  ) => {
    if (updateTask) {
      console.log(selectedTasks);
      const updatedTasks = selectedTasks!.reduce(
        (accumulator: any, edge: any) => {
          if (selectedTasks!.some((t: any) => t.taskId === edge.taskId)) {
            accumulator.push({
              taskOwnerId: edge.taskOwnerId,
              taskId: edge.taskId,
              taskName: edge.taskName,
              priority: edge.priority,
              status: edge.status.toUpperCase(),
              notes: edge.notes,
              startTimeScheduled: edge.startTimeScheduled.substring(0, 10),
              assignedGroups: edge.assignedGroups,
              assignedUsers: edge.assignedUsers,
              [field]: newValue,
            });
          }
          return accumulator;
        },
        [] as Array<ManagePersonalTask>
      );
      console.log(updatedTasks);
      const mutationInput = updatedTasks.map((task: ManagePersonalTask) =>
        ManagePersonalTaskInputMapper(task as ManagePersonalTask)
      );

      console.log(mutationInput);
      updatePersonalTask({
        variables: {
          inputs: mutationInput,
        },
      }).then((res: any) => {
        refetch(variables).then(() => {
          console.log("refetched");
        });
      });
    }
    setState(false);
  };

  const handleEditTaskClose = (
    updatedTask: ManagePersonalTask,
    editTask: boolean
  ) => {
    if (editTask) {
      updatePersonalTask({
        variables: {
          inputs: [ManagePersonalTaskInputMapper(updatedTask)],
        },
      }).then(() => {
        refetch(variables).then(() => {
          console.log("refetched");
        });
      });
    }
    setState(false);
  };

  const selectedTask = selectedTasks![0];

  switch (dialog) {
    case ContextMenuDialogs.AssignToUser:
      return (
        <AssignToUsersDialog
          isOpen={isOpen}
          handleClose={(assignedUsers, assignUsers) =>
            updateSelectedPersonalTask(
              "assignedUsers",
              assignedUsers,
              assignUsers
            )
          }
          selectedTask={selectedTask}
        />
      );
    case ContextMenuDialogs.AssignToGroup:
      return (
        <AssignToGroupsDialog
          isOpen={isOpen}
          handleClose={(assignedGroup, assignGroup) =>
            updateSelectedPersonalTask(
              "assignedGroups",
              assignedGroup,
              assignGroup
            )
          }
          selectedTask={selectedTask}
        />
      );
    case ContextMenuDialogs.AssignToSystem:
      return (
        <AssignToSystemDialog
          isOpen={isOpen}
          handleClose={(systemId, assignSystem) =>
            updateSelectedPersonalTask(
              "systemAssignedTo",
              systemId,
              assignSystem
            )
          }
          selectedTasks={selectedTasks!}
        />
      );
    case ContextMenuDialogs.RescheduleTask:
      const dateInput: DateInput = {
        dialogTitle: "Reschedule Task",
        dateInput: selectedTasks![0].startTimeScheduled,
      };
      return (
        <RescheduleTaskDialog
          isOpen={isOpen}
          handleClose={(date, updateTasks) =>
            updateSelectedPersonalTask("startTimeScheduled", date, updateTasks)
          }
          dateInput={dateInput}
          selectedTasks={selectedTasks!}
        />
      );
    case ContextMenuDialogs.EditTask:
      return (
        <EditTaskDialog
          isOpen={isOpen}
          handleClose={(task, editTask) => handleEditTaskClose(task, editTask)}
          selectedTask={selectedTask}
        />
      );
    default:
      return <React.Fragment />;
  }
}
