import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { SourcePlateInfoState as Twelve24TCSourcePlateState } from "features/WorklistTools/1224TC/state";
import { SourcePlateInfoState as PoolingSourcePlateState } from "features/WorklistTools/PoolingNormalization/state";

interface DiscardSourcePlateProps {
  index: number;
  item: PoolingSourcePlateState | Twelve24TCSourcePlateState;
  updateDiscardSourcePlate: (index: number, value: number) => void;
}
export const DiscardSourcePlate = ({
  item,
  index,
  updateDiscardSourcePlate,
}: DiscardSourcePlateProps) => {
  const discardSrcPlateAndsrcPlateDoesNotExist = item.plateBarcode == "";
  const srcPlateExistsAndNoDiscardSrcPlate =
    item.labwareTypeCode != "" && item.discardSourcePlate === -1;
  return (
    <FormControl
      variant="outlined"
      error={srcPlateExistsAndNoDiscardSrcPlate}
      size="small"
    >
      <InputLabel id="discard-source-plate-label">
        Discard Source Plate
      </InputLabel>
      <Select
        labelId="discard-source-plate-label"
        id="discard-source-plate-select"
        label="Discard Source Plate"
        size="small"
        value={item.discardSourcePlate}
        error={srcPlateExistsAndNoDiscardSrcPlate}
        disabled={discardSrcPlateAndsrcPlateDoesNotExist}
        onChange={(e) => {
          const val = e.target.value as number;
          updateDiscardSourcePlate(index, val);
        }}
      >
        <MenuItem value={-1}></MenuItem>
        <MenuItem value={0}>NO - Return to Incubator</MenuItem>
        <MenuItem value={1}>YES - Trash to Waste</MenuItem>
        <MenuItem value={2}>YES - Trash to deck (manual removal)</MenuItem>
      </Select>
      <FormHelperText>
        {srcPlateExistsAndNoDiscardSrcPlate ? "Value can't be undefined" : ""}
      </FormHelperText>
    </FormControl>
  );
};
