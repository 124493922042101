import { IWorklistToolInternalState } from "../shared/state/initial-state";
import { is96WellPlate } from "../shared/WorklistHelpers";
import {
  checkIfAllPlatesAre96Well,
  getMaxStampVolForPlate,
  plateContainsPartiallyFilledColumn,
} from "./helpers";
import { IThawToolInternalState } from "./state/initial-state";

export const getRequiredWorklistWarnings = (
  sourcePlateInfo: any,
  destPlateInfo: any,
  worklistValues: any,
  deadPlateBarcode: string,
  deadPlateType: string,
  internalState: IThawToolInternalState,
  worklistToolState: IWorklistToolInternalState
) => {
  const methodSettings = internalState.methodSettings;
  let warning: string[] = [];

  const generalErrors = Object.values(worklistToolState.errorMessages).filter(
    (message) => message !== ""
  );
  warning = [...generalErrors];

  if (!internalState.sourcePlateHasTubeBarcodeScan) {
    warning.push(
      "Source plate doesn't have a barcode scan. Please upload a barcode scan associated with this plate"
    );
  }

  if (sourcePlateInfo[0].wellInfo.some((e: any) => e.barcode == undefined)) {
    warning.push("Error Source Tube Barcodes are undefined");
  }

  if (
    destPlateInfo.some(
      (e: any) => e.plateBarcode === "" && e.labwareTypeCode !== ""
    )
  ) {
    warning.push("Missing Destination Plate Barcode");
  }

  if (deadPlateBarcode === "") {
    warning.push("Dead Plate Barcode not found");
  }

  if (deadPlateType === "") {
    warning.push("Dead Plate Type is required");
  }

  if (
    methodSettings.selectedSystem === 6 &&
    destPlateInfo.some(
      (e: any) => !is96WellPlate(e.labwareTypeCode) && e.labwareTypeCode !== ""
    )
  ) {
    warning.push("Destination Plate is not compatiable with this system");
  }

  if (
    methodSettings.selectedSystem === 6 &&
    plateContainsPartiallyFilledColumn(sourcePlateInfo[0].wellInfo.length)
  ) {
    warning.push(
      "Source Plate has partially filled columns. This is only allowed on Systems 3 and 10"
    );
  }

  if (methodSettings.resuspensionVol > 900) {
    warning.push("Resuspension Vol cannot be over 900");
  }

  if (methodSettings.resuspensionVol <= 0) {
    warning.push("Resuspension Vol cannot be less than or equal to 0");
  }

  if (methodSettings.topupVol < 0) {
    warning.push("TopUp Vol cannot be less than 0");
  }

  if (worklistValues.thawStamp.length > 0) {
    if (
      destPlateInfo.some(
        (e: any) =>
          e.preprocessPlate === true &&
          e.operatingVol <
            getMaxStampVolForPlate(worklistValues.thawStamp, e.index) +
              methodSettings.topupVol
      )
    ) {
      warning.push("Largest Stamp Vol + TopUp Vol greater than operating vol");
    }
  }

  if (!checkIfAllPlatesAre96Well(destPlateInfo)) {
    warning.push("Cannot have 96W and 12/24W Plates");
  }

  return warning;
};

export const getOptionalWorklistWarnings = (
  sourcePlateInfo: any,
  destPlateInfo: any,
  worklistValues: any,
  deadPlateBarcode: string,
  methodSettings: any
) => {
  const warning: string[] = [];
  if (
    destPlateInfo.some(
      (e: any) =>
        e.preprocessPlate == true &&
        methodSettings.topupVol < 0.25 * e.operatingVol
    )
  ) {
    warning.push("TopUp Vol less than 25% of operating vol");
  }
  return warning;
};
