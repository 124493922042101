import { createSlice } from "@reduxjs/toolkit";
import undoable, { excludeAction } from "redux-undo";
import { WorklistValues } from "../shared/interfaces";
import { CellNumberUpdateType } from "./PoolingNormalizationToolHelpers";
import {
  getIntermediatePlateInfoState,
  getSourcePlateInfoState,
} from "./state-helpers";

export interface PoolingNormalizationToolState {
  sourcePlateInfo: SourcePlateInfoState[];
  intPlateInfo: any[];
  destPlateInfo: DestPlateInfoState[];
  worklistValues: WorklistValuesState;
  // methodSettings: MethodSettingsState;
  deadPlateBarcode: string;
  deadPlateType: string;
}

export interface SourcePlateInfoState {
  plateBarcode: string;
  labwareTypeCode: string;
  operatingVol: number;
  rows: number[];
  cols: number[];
  wellInfo: any[];
  discardSourcePlate: number;
}

export interface IntermediatePlateInfoState {
  plateBarcode: string;
  labwareTypeCode: string;
  rows: number[];
  cols: number[];
  operatingVol: number;
  resuspensionVol: number;
}

export interface DestPlateInfoState {
  index: number;
  plateBarcode: string;
  labwareTypeCode: string;
  operatingVol: number;
  minOperatingVol: number;
  startingVol: number;
  rows: number[];
  cols: number[];
  preprocess: boolean;
  topup: boolean;
}

export interface WorklistWellMappingState {
  sourcePlateIndex: number;
  sourceWellId: string;
  destPlateIndex: number;
  destWellId: string;
  transferVol?: string;
}

type DestPlateKeys = keyof DestPlateInfoState;
export type DestPlateStringKeys = Extract<DestPlateKeys, string>;

interface ReduxAction {
  payload: {
    index: number;
    key: DestPlateStringKeys;
    value: string | number | number[] | boolean;
  };
}

export interface MethodSettingsState {
  selectedSystem: number;
  stampSubProcess: boolean;
  manuallySelectIntWells: boolean;
  dissociationTime: number;
  spinParamGForce: number;
  spinParamPercent: number;
  spinTime: number;
  spinAccel: number;
  spinDecel: number;
  pelletResuspensionVol: number;
  dissociationWashReagent: string;
  reFeedWellsRGT: string;
  dissociation: boolean;
  washBeforeDissociation: boolean;
  washAfterDissociation: boolean;
  preprocessPlate: boolean;
  refeedSourceWells: boolean;
  harvestWash: boolean;
  stampCols: number;
  stampRows: number;
  stampVolume: number;
  deadPlateBarcode: string;
  intPlateBarcode: string;
  intPlateType1: string;
  intPlateBarcode2: string;
  intPlateType2: string;
  cellNumber: number;
}

const MethodSettingsFields = {
  selectedSystem: "selectedSystem",
  stampSubProcess: "stampSubProcess",
  manuallySelectIntWells: "manuallySelectIntWells",
  dissociationTime: "dissociationTime",
  spinParamGForce: "spinParamGForce",
  spinParamPercent: "spinParamPercent",
  spinTime: "spinTime",
  spinAccel: "spinAccel",
  spinDecel: "spinDecel",
  pelletResuspensionVol: "pelletResuspensionVol",
  dissociationWashReagent: "dissociationWashReagent",
  reFeedWellsRGT: "reFeedWellsRGT",
  dissociation: "dissociation",
  washBeforeDissociation: "washBeforeDissociation",
  washAfterDissociation: "washAfterDissociation",
  preprocessPlate: "preprocessPlate",
  refeedSourceWells: "refeedSourceWells",
  harvestWash: "harvestWash",
  stampCols: "stampCols",
  stampRows: "stampRows",
  stampVolume: "stampVolume",
  deadPlateBarcode: "deadPlateBarcode",
  intPlateBarcode: "intPlateBarcode",
  intPlateType1: "intPlateType1",
  intPlateBarcode2: "intPlateBarcode2",
  intPlateType2: "intPlateType2",
  cellNumber: "cellNumber",
};

type MethodSettingKey = keyof MethodSettingsState;

export interface WorklistValuesState {
  harvestWells: WorklistWellMappingState[];
  int1ToInt2: WorklistWellMappingState[];
  int1ToInt3: WorklistWellMappingState[];
  int2ToInt3: WorklistWellMappingState[];
  int1ToDest: WorklistWellMappingState[];
  int2ToDest: WorklistWellMappingState[];
  int3ToDest: WorklistWellMappingState[];
  int1StampTopLeft: IStampTopLeftWorklistState[];
  int2StampTopLeft: IStampTopLeftWorklistState[];
  int3StampTopLeft: IStampTopLeftWorklistState[];
  referenceStampShape: any[];
  stampTopLeftTransfers: any[]; //not used in worklist, used to render stamp pattern in ui
}

export interface IStampTopLeftWorklistState {
  sourceWellId: string;
  destPlateIndex: number;
  destWellId: string;
}

export const poolingNormalizationInitialToolState: PoolingNormalizationToolState =
  {
    sourcePlateInfo: Array.from({ length: 8 }, () => getSourcePlateInfoState()),
    intPlateInfo: Array.from({ length: 3 }, () =>
      getIntermediatePlateInfoState()
    ),
    destPlateInfo: [
      {
        index: 0,
        plateBarcode: "",
        labwareTypeCode: "",
        operatingVol: 0,
        minOperatingVol: 0,
        startingVol: 0,
        rows: [],
        cols: [],
        preprocess: false,
        topup: false,
      },
    ],
    deadPlateBarcode: "",
    deadPlateType: "",
    worklistValues: {
      harvestWells: [],
      int1ToInt2: [],
      int1ToInt3: [],
      int2ToInt3: [],
      int1ToDest: [],
      int2ToDest: [],
      int3ToDest: [],
      int1StampTopLeft: [],
      int2StampTopLeft: [],
      int3StampTopLeft: [],
      referenceStampShape: [],
      stampTopLeftTransfers: [],
    },
  };

export const PoolingNormalizationToolStoreSlice = createSlice({
  name: "pooling-normalization-tool-info",
  initialState: poolingNormalizationInitialToolState,
  reducers: {
    RESET_STATE: (state) => {
      state.sourcePlateInfo =
        poolingNormalizationInitialToolState.sourcePlateInfo;
      state.destPlateInfo = poolingNormalizationInitialToolState.destPlateInfo;
      state.intPlateInfo = poolingNormalizationInitialToolState.intPlateInfo;
      state.worklistValues =
        poolingNormalizationInitialToolState.worklistValues;
      state.deadPlateBarcode =
        poolingNormalizationInitialToolState.deadPlateBarcode;
    },
    UPLOAD_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[action.payload.index].plateBarcode =
        action.payload.plateBarcode;
      state.sourcePlateInfo[action.payload.index].labwareTypeCode =
        action.payload.labwareTypeCode;
      state.sourcePlateInfo[action.payload.index].operatingVol =
        action.payload.operatingVol;
      state.sourcePlateInfo[action.payload.index].rows = action.payload.rows;
      state.sourcePlateInfo[action.payload.index].cols = action.payload.cols;
      state.sourcePlateInfo[action.payload.index].wellInfo =
        action.payload.wellInfo;
    },
    UPDATE_DISCARD_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[action.payload.index].discardSourcePlate =
        action.payload.value;
    },
    UPLOAD_INTERMEDIATE_PLATE_FROM_SOURCE_PLATE: (state, action) => {
      state.intPlateInfo[0].plateBarcode = action.payload.plateBarcode;
      state.intPlateInfo[0].labwareTypeCode = action.payload.labwareTypeCode;
      state.intPlateInfo[0].operatingVol = action.payload.operatingVol;
      state.intPlateInfo[0].rows = action.payload.rows;
      state.intPlateInfo[0].cols = action.payload.cols;
      state.worklistValues.harvestWells = action.payload.harvestWells;
    },
    ADD_DESTINATION_PLATE: (state, action) => {
      state.destPlateInfo.push({
        index: state.destPlateInfo[state.destPlateInfo.length - 1].index + 1,
        plateBarcode: "",
        labwareTypeCode: "",
        operatingVol: 0,
        minOperatingVol: 0,
        startingVol: 0,
        rows: [],
        cols: [],
        preprocess: false,
        topup: false,
      });
    },
    SET_DESTINATION_PLATE: (state, action) => {
      state.destPlateInfo[action.payload.index].plateBarcode =
        action.payload.plateBarcode;
      state.destPlateInfo[action.payload.index].operatingVol =
        action.payload.operatingVol;
      state.destPlateInfo[action.payload.index].minOperatingVol =
        action.payload.minOperatingVol;
      state.destPlateInfo[action.payload.index].labwareTypeCode =
        action.payload.labwareTypeCode;
      state.destPlateInfo[action.payload.index].rows = action.payload.rows;
      state.destPlateInfo[action.payload.index].cols = action.payload.cols;
      state.destPlateInfo[action.payload.index].topup = action.payload.topup;
    },
    CLEAR_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[action.payload.index].plateBarcode = "";
      state.sourcePlateInfo[action.payload.index].labwareTypeCode = "";
      state.sourcePlateInfo[action.payload.index].operatingVol = 0;
      state.sourcePlateInfo[action.payload.index].rows = [];
      state.sourcePlateInfo[action.payload.index].cols = [];
      state.sourcePlateInfo[action.payload.index].wellInfo = [];
      state.worklistValues.harvestWells =
        state.worklistValues.harvestWells.filter(
          (e) => e.sourcePlateIndex !== action.payload.index
        );
    },
    CLEAR_INTERMEDIATE_PLATE: (state, action) => {
      state.intPlateInfo[action.payload.index].plateBarcode = "";
      state.intPlateInfo[action.payload.index].labwareTypeCode = "";
      state.intPlateInfo[action.payload.index].operatingVol = 0;
      state.intPlateInfo[action.payload.index].resuspensionVol = 0;
      state.intPlateInfo[action.payload.index].rows = [];
      state.intPlateInfo[action.payload.index].cols = [];
    },
    CLEAR_DESTINATION_PLATE: (state, action) => {
      state.destPlateInfo[action.payload.index].plateBarcode = "";
      state.destPlateInfo[action.payload.index].operatingVol = 0;
      state.destPlateInfo[action.payload.index].startingVol = 0;
      state.destPlateInfo[action.payload.index].labwareTypeCode = "";
      state.destPlateInfo[action.payload.index].rows = [];
      state.destPlateInfo[action.payload.index].cols = [];

      state.worklistValues.int1ToDest = state.worklistValues.int1ToDest.filter(
        (e) => e.destPlateIndex !== action.payload.index
      );
      state.worklistValues.int2ToDest = state.worklistValues.int2ToDest.filter(
        (e) => e.destPlateIndex !== action.payload.index
      );
      state.worklistValues.int3ToDest = state.worklistValues.int3ToDest.filter(
        (e) => e.destPlateIndex !== action.payload.index
      );

      state.worklistValues.int1StampTopLeft =
        state.worklistValues.int1StampTopLeft.filter(
          (e) => e.destPlateIndex !== action.payload.index
        );
      state.worklistValues.int2StampTopLeft =
        state.worklistValues.int2StampTopLeft.filter(
          (e) => e.destPlateIndex !== action.payload.index
        );
      state.worklistValues.int3StampTopLeft =
        state.worklistValues.int3StampTopLeft.filter(
          (e) => e.destPlateIndex !== action.payload.index
        );
      state.worklistValues.stampTopLeftTransfers =
        state.worklistValues.stampTopLeftTransfers.filter(
          (e) => e.destPlateIndex !== action.payload.index
        );
    },
    CLONE_DESTINATION_PLATE: (state, action) => {
      const destinationIndex = action.payload.destinationIndex;

      const newInt1ToDest = state.worklistValues.int1ToDest.filter(
        (e) => e.destPlateIndex === destinationIndex
      );
      const newInt2ToDest = state.worklistValues.int2ToDest.filter(
        (e) => e.destPlateIndex === destinationIndex
      );
      const newInt3ToDest = state.worklistValues.int3ToDest.filter(
        (e) => e.destPlateIndex === destinationIndex
      );

      const newInt1StampTopLeft = state.worklistValues.int1StampTopLeft.filter(
        (e) => e.destPlateIndex === destinationIndex
      );
      const newInt2StampTopLeft = state.worklistValues.int2StampTopLeft.filter(
        (e) => e.destPlateIndex === destinationIndex
      );
      const newInt3StampTopLeft = state.worklistValues.int3StampTopLeft.filter(
        (e) => e.destPlateIndex === destinationIndex
      );

      const newStampTopLeftTransfers =
        state.worklistValues.stampTopLeftTransfers.filter(
          (e) => e.destPlateIndex === destinationIndex
        );
      state.worklistValues.int1ToDest.push(
        ...newInt1ToDest.map((item) => {
          return { ...item, destPlateIndex: destinationIndex + 1 };
        })
      );
      state.worklistValues.int2ToDest.push(
        ...newInt2ToDest.map((item) => {
          return { ...item, destPlateIndex: destinationIndex + 1 };
        })
      );
      state.worklistValues.int3ToDest.push(
        ...newInt3ToDest.map((item) => {
          return { ...item, destPlateIndex: destinationIndex + 1 };
        })
      );

      state.worklistValues.int1StampTopLeft.push(
        ...newInt1StampTopLeft.map((item) => {
          return { ...item, destPlateIndex: destinationIndex + 1 };
        })
      );
      state.worklistValues.int2StampTopLeft.push(
        ...newInt2StampTopLeft.map((item) => {
          return { ...item, destPlateIndex: destinationIndex + 1 };
        })
      );
      state.worklistValues.int3StampTopLeft.push(
        ...newInt3StampTopLeft.map((item) => {
          return { ...item, destPlateIndex: destinationIndex + 1 };
        })
      );

      state.worklistValues.stampTopLeftTransfers.push(
        ...newStampTopLeftTransfers.map((item) => {
          return { ...item, destPlateIndex: destinationIndex + 1 };
        })
      );
    },
    SET_INTERMEDIATE_PLATE: (state, action) => {
      state.intPlateInfo[action.payload.index].operatingVol =
        action.payload.operatingVol;
      state.intPlateInfo[action.payload.index].labwareTypeCode =
        action.payload.labwareTypeCode;
      state.intPlateInfo[action.payload.index].rows = action.payload.rows;
      state.intPlateInfo[action.payload.index].cols = action.payload.cols;
    },
    UPDATE_AUX_PLATEBARCODES: (state, action) => {
      if (state.intPlateInfo[0].labwareTypeCode !== "") {
        state.intPlateInfo[0].plateBarcode = action.payload.intPlateBarcode;
      }

      if (state.intPlateInfo[1].labwareTypeCode !== "") {
        state.intPlateInfo[1].plateBarcode = action.payload.intPlateBarcode2;
      }

      if (state.intPlateInfo[2].labwareTypeCode !== "") {
        state.intPlateInfo[2].plateBarcode = action.payload.intPlateBarcode3;
      }

      state.deadPlateBarcode = action.payload.deadPlateBarcode;
    },
    UPDATE_INT_PLATE_INFO: (state, action) => {
      state.intPlateInfo[action.payload.index][action.payload.key] =
        action.payload.value;
    },
    UPDATE_DEAD_PLATEBARCODE: (state, action) => {
      state.deadPlateBarcode = action.payload.value;
    },
    UPDATE_DEAD_PLATETYPE: (state, action) => {
      state.deadPlateType = action.payload.value;
    },
    INT_TO_DEST_ADDED: (state, action) => {
      switch (action.payload.index) {
        case 0:
          state.worklistValues.int1ToDest.push(...action.payload.selections);
          break;
        case 1:
          state.worklistValues.int2ToDest.push(...action.payload.selections);
          break;
        case 2:
          state.worklistValues.int3ToDest.push(...action.payload.selections);
          break;
      }
    },
    SRC_TO_INT_ADDED: (state, action) => {
      state.worklistValues.harvestWells = action.payload.selections;
    },
    INT1_TO_INT2_ADDED: (state, action) => {
      state.worklistValues.int1ToInt2.push(...action.payload.selections);
    },
    SRC_TO_INT_MANUALLY_ADDED: (state, action) => {
      state.worklistValues.harvestWells.push(...action.payload.selections);
    },
    INT1_TO_INT2_MANUALLY_ADDED: (state, action) => {
      state.worklistValues.int1ToInt2.push(...action.payload.selections);
    },
    INT1_TO_INT3_MANUALLY_ADDED: (state, action) => {
      state.worklistValues.int1ToInt3.push(...action.payload.selections);
    },
    INT2_TO_INT3_MANUALLY_ADDED: (state, action) => {
      state.worklistValues.int2ToInt3.push(...action.payload.selections);
    },
    REFERENCE_STAMP_WELL_ADDED: (state, action) => {
      state.worklistValues.referenceStampShape.push(
        ...action.payload.selections
      );

      state.worklistValues.referenceStampShape.sort((a, b) => {
        const aRowVal = a.charCodeAt(0);
        const bRowVal = b.charCodeAt(0);

        const aColVal = parseInt(a.substring(1));
        const bColVal = parseInt(b.substring(1));

        return aRowVal - bRowVal || aColVal - bColVal;
      });
    },
    REMOVE_STAMP_WELL: (state, action) => {
      state.worklistValues.referenceStampShape =
        action.payload.referenceStampShape;
    },
    INT_TO_DEST_STAMP_TOP_LEFT_ADDED: (state, action) => {
      state.worklistValues.stampTopLeftTransfers.push(
        ...action.payload.selections
      );
      switch (action.payload.index) {
        case 0:
          state.worklistValues.int1StampTopLeft.push({
            ...action.payload.stampTopLeft,
          });
          break;
        case 1:
          state.worklistValues.int2StampTopLeft.push({
            ...action.payload.stampTopLeft,
          });
          break;
        case 2:
          state.worklistValues.int3StampTopLeft.push({
            ...action.payload.stampTopLeft,
          });
          break;
      }
    },
    CLEAR_INT_WELLS: (state) => {
      state.worklistValues.harvestWells = [];
      state.worklistValues.int1ToInt2 = [];
      state.worklistValues.int1ToInt3 = [];
      state.worklistValues.int2ToInt3 = [];
    },
    UPDATE_INTERMEDIATE_PLATE_INFO: (state, action) => {
      state.intPlateInfo[action.payload.index][action.payload.key] =
        action.payload.value;
    },
    UPDATE_DESTINATION_PLATE_INFO: (state, action) => {
      const index = action.payload.index;
      const key = action.payload.key as DestPlateStringKeys;
      const value = action.payload.value;
      if (typeof state.destPlateInfo[index][key] == typeof value) {
        //@ts-ignore
        state.destPlateInfo[index][key] = value;
      }
    },
    UPLOAD_SOURCE_INT_DEAD_AND_DEST_PLATE_INFO: (state, action) => {
      state.sourcePlateInfo = action.payload.sourcePlateInfo;
      state.intPlateInfo = action.payload.intPlateInfo;
      state.destPlateInfo = action.payload.destPlateInfo;
      state.deadPlateBarcode = action.payload.deadPlateBarcode;
      state.deadPlateType = action.payload.deadPlateType;
    },
    UPLOAD_WORKLIST_VALUES: (state, action) => {
      const properties: string[] = [
        "harvestWells",
        "int1ToInt2",
        "int1ToInt3",
        "int2ToInt3",
        "int1ToDest",
        "int2ToDest",
        "int3ToDest",
        "int1StampTopLeft",
        "int2StampTopLeft",
        "int3StampTopLeft",
        "referenceStampShape",
        "stampTopLeftTransfers",
      ];
      properties.forEach((property) => {
        const value = Reflect.get(action.payload, property);
        Reflect.set(state.worklistValues, property, value);
      });
    },
    UPDATE_INT1_TO_INT2_CELL_NUMBERS: (state, action) => {
      switch (action.payload.updateType) {
        case CellNumberUpdateType.ALL:
          const int1ToInt2 = state.worklistValues.int1ToInt2.map((e) => ({
            ...e,
            transferVol: action.payload.transferVol,
          }));
          state.worklistValues.int1ToInt2 = int1ToInt2;
          break;
        case CellNumberUpdateType.EMPTY:
          const int1ToInt2Mapping = state.worklistValues.int1ToInt2.filter(
            (e) => e.transferVol !== ""
          );

          let newInt1ToInt2Mapping = state.worklistValues.int1ToInt2.filter(
            (e) => e.transferVol === ""
          );

          newInt1ToInt2Mapping = newInt1ToInt2Mapping.map((e) => ({
            ...e,
            transferVol: action.payload.transferVol,
          }));

          state.worklistValues.int1ToInt2 = [
            ...int1ToInt2Mapping,
            ...newInt1ToInt2Mapping,
          ];
          break;
        case CellNumberUpdateType.INDIVIDUAL:
          const int1ToInt2Map: WorklistValues[] = [];
          for (const item of action.payload.rows) {
            int1ToInt2Map.push(item);
          }
          state.worklistValues.int1ToInt2 = int1ToInt2Map;
          break;
      }
    },
    UPDATE_INT2_TO_INT3_CELL_NUMBERS: (state, action) => {
      switch (action.payload.updateType) {
        case CellNumberUpdateType.ALL:
          const int2ToInt3 = state.worklistValues.int2ToInt3.map((e) => ({
            ...e,
            transferVol: action.payload.transferVol,
          }));
          state.worklistValues.int2ToInt3 = int2ToInt3;
          break;
        case CellNumberUpdateType.EMPTY:
          const int2ToInt3Mapping = state.worklistValues.int2ToInt3.filter(
            (e) => e.transferVol !== ""
          );

          let newInt2ToInt3Mapping = state.worklistValues.int2ToInt3.filter(
            (e) => e.transferVol === ""
          );

          newInt2ToInt3Mapping = newInt2ToInt3Mapping.map((e) => ({
            ...e,
            transferVol: action.payload.transferVol,
          }));

          state.worklistValues.int2ToInt3 = [
            ...int2ToInt3Mapping,
            ...newInt2ToInt3Mapping,
          ];
          break;
        case CellNumberUpdateType.INDIVIDUAL:
          const int2ToInt3Map: WorklistValues[] = [];
          for (const item of action.payload.rows) {
            int2ToInt3Map.push(item);
          }
          state.worklistValues.int2ToInt3 = int2ToInt3Map;
          break;
      }
    },
    UPDATE_INT1_TO_INT3_CELL_NUMBERS: (state, action) => {
      switch (action.payload.updateType) {
        case CellNumberUpdateType.ALL:
          const int1ToInt3 = state.worklistValues.int1ToInt3.map((e) => ({
            ...e,
            transferVol: action.payload.transferVol,
          }));
          state.worklistValues.int1ToInt3 = int1ToInt3;
          break;
        case CellNumberUpdateType.EMPTY:
          const int1ToInt3Mapping = state.worklistValues.int1ToInt3.filter(
            (e) => e.transferVol !== ""
          );

          let newInt1ToInt3Mapping = state.worklistValues.int1ToInt3.filter(
            (e) => e.transferVol === ""
          );

          newInt1ToInt3Mapping = newInt1ToInt3Mapping.map((e) => ({
            ...e,
            transferVol: action.payload.transferVol,
          }));

          state.worklistValues.int1ToInt3 = [
            ...int1ToInt3Mapping,
            ...newInt1ToInt3Mapping,
          ];
          break;
        case CellNumberUpdateType.INDIVIDUAL:
          const int1ToInt3Map: WorklistValues[] = [];
          for (const item of action.payload.rows) {
            int1ToInt3Map.push(item);
          }
          state.worklistValues.int1ToInt3 = int1ToInt3Map;
          break;
      }
    },
    UPDATE_ALL_DEST_STAMP_CELL_NUMBER: (state, action) => {
      const int1ToDest = state.worklistValues.int1ToDest.map((e) => ({
        ...e,
        transferVol: action.payload.transferVol,
      }));

      const int2ToDest = state.worklistValues.int2ToDest.map((e) => ({
        ...e,
        transferVol: action.payload.transferVol,
      }));

      const int3ToDest = state.worklistValues.int3ToDest.map((e) => ({
        ...e,
        transferVol: action.payload.transferVol,
      }));

      state.worklistValues.int1ToDest = int1ToDest;
      state.worklistValues.int2ToDest = int2ToDest;
      state.worklistValues.int3ToDest = int3ToDest;
    },
    UPDATE_EMPTY_DEST_CELL_NUMBERS: (state, action) => {
      //Sets blank cell numbers, leaves existing cell number as is
      const int1ToDestMapping = state.worklistValues.int1ToDest.filter(
        (e) => e.transferVol !== ""
      );

      const int2ToDestMapping = state.worklistValues.int2ToDest.filter(
        (e) => e.transferVol !== ""
      );

      const int3ToDestMapping = state.worklistValues.int3ToDest.filter(
        (e) => e.transferVol !== ""
      );

      let newInt1Mapping = state.worklistValues.int1ToDest.filter(
        (e) => e.transferVol === ""
      );
      let newInt2Mapping = state.worklistValues.int2ToDest.filter(
        (e) => e.transferVol === ""
      );
      let newInt3Mapping = state.worklistValues.int3ToDest.filter(
        (e) => e.transferVol === ""
      );
      newInt1Mapping = newInt1Mapping.map((e) => ({
        ...e,
        transferVol: action.payload.transferVol,
      }));

      newInt2Mapping = newInt2Mapping.map((e) => ({
        ...e,
        transferVol: action.payload.transferVol,
      }));

      newInt3Mapping = newInt3Mapping.map((e) => ({
        ...e,
        transferVol: action.payload.transferVol,
      }));
      state.worklistValues.int1ToDest = [
        ...int1ToDestMapping,
        ...newInt1Mapping,
      ];
      state.worklistValues.int2ToDest = [
        ...int2ToDestMapping,
        ...newInt2Mapping,
      ];
      state.worklistValues.int3ToDest = [
        ...int3ToDestMapping,
        ...newInt3Mapping,
      ];
    },
    UPDATE_DEST_CELL_NUMBER: (state, action) => {
      const int1ToDest: WorklistValues[] = [];
      const int2ToDest: WorklistValues[] = [];
      const int3ToDest: WorklistValues[] = [];
      for (const item of action.payload.rows) {
        if (item.sourcePlateIndex === 0) {
          int1ToDest.push(item);
        } else if (item.sourcePlateIndex === 1) {
          int2ToDest.push(item);
        } else if (item.sourcePlateIndex === 2) {
          int3ToDest.push(item);
        }
      }

      state.worklistValues.int1ToDest = int1ToDest;
      state.worklistValues.int2ToDest = int2ToDest;
      state.worklistValues.int3ToDest = int3ToDest;
    },
    UPLOAD_PLATES_FROM_TEMPLATE: (state, action) => {
      for (let i = 0; i < 8; i++) {
        if (i >= action.payload.sourcePlates.length) break;
        state.sourcePlateInfo[i] = action.payload.sourcePlates[i];
      }

      for (let i = 0; i < 3; i++) {
        if (i >= action.payload.intPlates.length) break;
        state.intPlateInfo[i] = action.payload.intPlates[i];
      }

      state.destPlateInfo = action.payload.destPlates;
      state.deadPlateType = action.payload.deadPlateType;
    },
    UPLOAD_WORKLIST_VALUES_FROM_TEMPLATE: (state, action) => {
      state.worklistValues.harvestWells = action.payload.harvestWells;
      state.worklistValues.int1ToInt2 = action.payload.int1ToInt2;
      state.worklistValues.int2ToInt3 = action.payload.int2ToInt3;
      state.worklistValues.int1ToInt3 = action.payload.int1ToInt3;
      state.worklistValues.int1ToDest = action.payload.int1ToDest;
      state.worklistValues.int2ToDest = action.payload.int2ToDest;
      state.worklistValues.int3ToDest = action.payload.int3ToDest;
      state.worklistValues.referenceStampShape =
        action.payload.referenceStampShape;
      state.worklistValues.stampTopLeftTransfers =
        action.payload.stampTopLeftTransfers;
      state.worklistValues.int1StampTopLeft = action.payload.int1StampTopLeft;
      state.worklistValues.int2StampTopLeft = action.payload.int2StampTopLeft;
      state.worklistValues.int3StampTopLeft = action.payload.int3StampTopLeft;
    },
  },
});

export const PoolingNormalizationToolReducer =
  PoolingNormalizationToolStoreSlice.reducer;

export const PoolingNormalizationToolActions =
  PoolingNormalizationToolStoreSlice.actions;

export const PoolingNormalizationTool = undoable(
  PoolingNormalizationToolReducer,
  {
    filter: excludeAction([
      PoolingNormalizationToolActions.SRC_TO_INT_ADDED.toString(),
      PoolingNormalizationToolActions.INT1_TO_INT2_ADDED.toString(),
      PoolingNormalizationToolActions.UPDATE_DESTINATION_PLATE_INFO.toString(),
      PoolingNormalizationToolActions.UPLOAD_WORKLIST_VALUES_FROM_TEMPLATE.toString(),
    ]),
  }
);
