import { makeVar } from "@apollo/client";
import { PoolingNormalizationStep, SelectionProcess } from "features/WorklistTools/shared/interfaces";
import { WellSelection } from "../../shared/interfaces";

export enum StampSingleChannelPriority {
  StampFirst = 0,
  SingleChannelFirst = 1,
}

export interface IPoolingNormalizationToolInternalState {
  uploadedIntermediatePlate: boolean;
  uploadedWorklist: boolean;
  destSelectionProcess: SelectionProcess;
  randomizeWells: boolean;
  enablePooling: boolean;
  selectedIntermediateStampPosition: number;
  selectedSourceWells: WellSelection[];
  selectedIntToDestWells: WellSelection[];
  selectedInt1Wells: WellSelection[];
  selectedInt2Wells: WellSelection[];
  selectedInt3Wells: WellSelection[];
  step: PoolingNormalizationStep;
  sourceIntIndex?: number;
  destinationIntIndex?: number;
  selectedDestinationIndex?: number;
  requiredWarnings: string[];
  optionalWarnings: string[];
  worklist: any[];
  worklistPreviewOpen: boolean;
  hoverWellId: string;
  stampSingleChannelPriority?: StampSingleChannelPriority | undefined;
  //seedFirstPriority: boolean;
  methodSettings: IPoolingMethodSettings;
}

export interface IPoolingMethodSettings {
  selectedSystem: number;
  arraySystemAutomationMethodId: number;
  numberOfAliquots: number;
  aliquotVolume: number;
  dissociationTime: number;
  spinParamGForce: number;
  spinParamPercent: number;
  spinTime: number;
  spinAccel: number;
  spinDecel: number;
  dissociationWashRGT: number;
  reFeedWellsRGT: number;
  harvestWashRGT: number;
  extraPelletWash: boolean;
  dissociation: boolean;
  washBeforeDissociation: boolean;
  washAfterDissociation: boolean;
  stampReuseTips: boolean | undefined;
  reFeedSourceWells: boolean;
  harvestWash: boolean;
  stampVolume: number;
  groupedSeedingPriority: { id: string; content: string }[];
  seedingPriority_12_24_Well: string[];
  seedingPriority_96_384_Well: string[];
  // Remove comments to add Source96HeadProcessing Logic
  // source96HeadProcessing: boolean;
  int196HeadProcessing: boolean;
  reducedVolWellWash: boolean;
  reducedPelResusSpeed: boolean;
  int1CountAliquot: number;
  int1SpinResuspend: number;
}

export const PoolingNormalizationToolInternalState =
  makeVar<IPoolingNormalizationToolInternalState>({
    uploadedIntermediatePlate: false,
    uploadedWorklist: false,
    destSelectionProcess: SelectionProcess.Stamp,
    randomizeWells: false,
    enablePooling: false,
    selectedIntermediateStampPosition: -1,
    selectedSourceWells: [],
    selectedIntToDestWells: [], // used for int to dest selection
    selectedInt1Wells: [],
    selectedInt2Wells: [],
    selectedInt3Wells: [],
    step: PoolingNormalizationStep.SelectSourceToIntermediate,
    sourceIntIndex: undefined,
    destinationIntIndex: undefined,
    selectedDestinationIndex: undefined,
    requiredWarnings: [],
    optionalWarnings: [],
    worklist: [],
    worklistPreviewOpen: false,
    hoverWellId: "",
    stampSingleChannelPriority: undefined,
    methodSettings: {
      selectedSystem: 0,
      arraySystemAutomationMethodId: 299,
      numberOfAliquots: 1,
      aliquotVolume: -1,
      dissociationTime: 0,
      spinParamGForce: 0,
      spinParamPercent: 0,
      spinTime: 0,
      spinAccel: 0,
      spinDecel: 0,
      dissociationWashRGT: 0,
      reFeedWellsRGT: 0,
      harvestWashRGT: 0,
      extraPelletWash: false,
      dissociation: true,
      washBeforeDissociation: false,
      washAfterDissociation: false,
      stampReuseTips: undefined,
      reFeedSourceWells: false,
      harvestWash: false,
      stampVolume: 0,
      groupedSeedingPriority: [
        { id: "12/24W", content: "12/24W Group" },
        { id: "96/384W", content: "96/384W Group" },
      ],
      seedingPriority_12_24_Well: [],
      seedingPriority_96_384_Well: [],
      // Remove comments to add Source96HeadProcessing Logic
      // source96HeadProcessing: false,
      int196HeadProcessing: true,
      reducedVolWellWash: false,
      reducedPelResusSpeed: false,
      int1CountAliquot: 1,
      int1SpinResuspend: 1,
    },
  });
