import {} from "actions/normalized-passage.actions";
import {
  PlatePosition,
  ReactSelectableFast,
  SelectionProcess,
  SelectionType,
  WorklistValues,
} from "features/WorklistTools/shared/interfaces";
import {
  applyRandomizeWells,
  applyStampShapeToSource,
  arrangeColWise,
  arrangeRowWise,
  checkForDoubleSelectionWithMultipleDestSelection,
  getWellSelectionType,
  randomizeSelectedWells,
  stampSourceToDestination,
  stampTo384WDestination,
} from "features/WorklistTools/shared/WorklistHelpers";
import { Alert, AlertType } from "shared-components/toast";
import { WorklistValuesState } from "../../state";
import { IPoolingNormalizationToolInternalState } from "../../state/initial-state";
import { addDestinationWellMapping } from "./addDestinationWellMapping";

export const selectDestWells = async (
  poolingInternalState: IPoolingNormalizationToolInternalState,
  intPlateInfo: any,
  destPlateInfo: any,
  worklistValues: WorklistValuesState,
  dispatch: (action: { type: string; payload: any }) => void,
  selectedWells: ReactSelectableFast[],
  isConfirmed: (
    prompt: string,
    bullets?: string[] | undefined
  ) => Promise<boolean>
) => {
  const destSelection = selectedWells.map((well) => ({
    plateIndex: well.props.plateIndex,
    plateWellId: well.props.plateWellId,
    isSelectable: false,
  }));

  if (destSelection.length === 0) return;
  if (poolingInternalState.selectedIntermediateStampPosition > -1) {
    const intermediateStampPosition =
      poolingInternalState.selectedIntermediateStampPosition;

    if (destSelection.length > 1) {
      Alert({
        type: AlertType.ERROR,
        message: "You can only select one destination well to stamp to",
      });
      return;
    }
    if (
      !poolingInternalState.selectedIntToDestWells.length ||
      poolingInternalState.selectedIntToDestWells[0].plateIndex !==
        poolingInternalState.selectedIntermediateStampPosition - 1
    ) {
      Alert({
        type: AlertType.ERROR,
        message: `You must select one well from Intermediate ${poolingInternalState.selectedIntermediateStampPosition}`,
      });
      return;
    }

    if (poolingInternalState.methodSettings.stampReuseTips) {
      const getStampTopLeftSourceWell = () => {
        switch (poolingInternalState.selectedIntermediateStampPosition - 1) {
          case 0:
            return worklistValues.int1StampTopLeft.map((e) => e.sourceWellId);
          case 1:
            return worklistValues.int2StampTopLeft.map((e) => e.sourceWellId);
          case 2:
            return worklistValues.int3StampTopLeft.map((e) => e.sourceWellId);
        }
      };
      const intStampTopLeft = getStampTopLeftSourceWell();

      const selectedIntWell =
        poolingInternalState.selectedIntToDestWells[0].plateWellId;
      if (
        intStampTopLeft!.length > 0 &&
        !intStampTopLeft?.includes(selectedIntWell)
      ) {
        const confirmed = await isConfirmed(
          `Are you sure you want to stamp? This will cause a cross contamination 
          because Stamp Reuse Tips is enabled and you have already stamped from a different 
          intermediate well`
        );

        if (!confirmed) return;
      }
    }

    const sourceStamp = applyStampShapeToSource(
      poolingInternalState.selectedIntToDestWells[0],
      worklistValues.referenceStampShape
    );
    if (!sourceStamp.length) {
      Alert({
        type: AlertType.ERROR,
        message: "Stamp pattern doesn not fit source plate",
      });
      return;
    }

    const currentSingleChannelWorklistValues =
      poolingInternalState.selectedIntermediateStampPosition === 1
        ? worklistValues.int1ToDest
        : poolingInternalState.selectedIntermediateStampPosition === 2
        ? worklistValues.int2ToDest
        : worklistValues.int3ToDest;

    const currentStampWorklistValues =
      poolingInternalState.selectedIntermediateStampPosition === 1
        ? worklistValues.stampTopLeftTransfers.filter(
            (e) => e.sourcePlateIndex === 0
          )
        : poolingInternalState.selectedIntermediateStampPosition === 2
        ? worklistValues.stampTopLeftTransfers.filter(
            (e) => e.sourcePlateIndex === 1
          )
        : worklistValues.stampTopLeftTransfers.filter(
            (e) => e.sourcePlateIndex === 2
          );
    let mapping = [];
    if (destPlateInfo[destSelection[0].plateIndex].rows.length > 8) {
      mapping = stampTo384WDestination(
        [...currentSingleChannelWorklistValues, ...currentStampWorklistValues],
        sourceStamp,
        destSelection[0],
        PlatePosition.Destination,
        poolingInternalState.enablePooling
      );
    } else {
      mapping = stampSourceToDestination(
        [...currentSingleChannelWorklistValues, ...currentStampWorklistValues],
        sourceStamp,
        destSelection[0],
        PlatePosition.Destination,
        poolingInternalState.enablePooling
      );
    }
    if (!mapping.length) {
      Alert({
        type: AlertType.ERROR,
        message: "Stamp pattern doesn not fit destination plate",
      });
      return;
    }

    //get all wells being stamped from intermediate plate
    const intermediateWells = sourceStamp.map((item) => item.plateWellId);
    const intermediateWellsToCheck = worklistValues.stampTopLeftTransfers
      .filter(
        (e) =>
          e.sourcePlateIndex ===
            poolingInternalState.selectedIntermediateStampPosition - 1 &&
          intermediateWells.includes(e.sourceWellId)
      )
      .map((item) => item.sourceWellId)
      .concat(intermediateWells);
    const intermediateWellCount = getWellStampCount(intermediateWellsToCheck);

    //get all wells being stamped into destination plate
    const destinationWells = mapping.map((item) => item.destWellId);
    const destinationWellsToCheck = worklistValues.stampTopLeftTransfers
      .filter(
        (e) =>
          e.destPlateIndex == destSelection[0].plateIndex &&
          destinationWells.includes(e.destWellId)
      )
      .map((item) => item.destWellId)
      .concat(destinationWells);
    const destinationWellCount = getWellStampCount(destinationWellsToCheck);

    if (
      !checkForIntermediateStampVolumeIssues(
        intermediateWellCount,
        poolingInternalState.methodSettings.stampVolume,
        intPlateInfo[intermediateStampPosition - 1].resuspensionVol
      )
    )
      return;
    if (
      !checkForDestinationStampVolumeIssues(
        destinationWellCount,
        destPlateInfo[destSelection[0].plateIndex].operatingVol,
        destPlateInfo[destSelection[0].plateIndex].startingVol,
        poolingInternalState.methodSettings.stampVolume
      )
    )
      return;

    addDestinationWellMapping(
      dispatch,
      poolingInternalState.selectedIntermediateStampPosition - 1,
      mapping,
      destPlateInfo,
      worklistValues,
      destSelection,
      true
    );
    return;
  }

  if (poolingInternalState.selectedIntToDestWells.length === 0) {
    Alert({
      type: AlertType.ERROR,
      message: "You must select an Intermediate well",
    });
    return;
  }

  let randomizedWells = [...poolingInternalState.selectedIntToDestWells];
  if (poolingInternalState.randomizeWells) {
    randomizedWells = await randomizeSelectedWells(randomizedWells);
  }
  const selectionType = getWellSelectionType(
    poolingInternalState.selectedIntToDestWells,
    destSelection
  );
  const sourceIntIndex =
    poolingInternalState.selectedIntToDestWells[0].plateIndex;
  let wellMapping: WorklistValues[] = [];

  const currentWorklistValues =
    sourceIntIndex === 0
      ? worklistValues.int1ToDest
      : sourceIntIndex === 1
      ? worklistValues.int2ToDest
      : worklistValues.int3ToDest;
  switch (selectionType) {
    case SelectionType.NoSourceWellSelected:
      Alert({
        type: AlertType.ERROR,
        message: "Please select a source well(s)",
      });
      break;
    case SelectionType.SelectMultipleSourceAndDestWells:
      Alert({
        type: AlertType.ERROR,
        message: "You can only select 1 Destination Well",
      });
      break;
    case SelectionType.SelectMultipleSourceAndOneDestWell:
      switch (poolingInternalState.destSelectionProcess) {
        case SelectionProcess.Stamp:
          wellMapping = stampSourceToDestination(
            currentWorklistValues,
            poolingInternalState.selectedIntToDestWells,
            destSelection[0],
            PlatePosition.Destination,
            poolingInternalState.enablePooling
          );
          if (wellMapping.length === 0) {
            Alert({
              type: AlertType.ERROR,
              message: "Selection does not fit in Dest Plate.",
            });
            return;
          }
          addDestinationWellMapping(
            dispatch,
            sourceIntIndex,
            poolingInternalState.randomizeWells
              ? applyRandomizeWells(wellMapping, randomizedWells)
              : wellMapping,
            destPlateInfo,
            worklistValues,
            destSelection,
            false
          );
          break;
        case SelectionProcess.RowWise:
          wellMapping = await arrangeRowWise(
            currentWorklistValues,
            poolingInternalState.selectedIntToDestWells,
            destSelection[0],
            destPlateInfo[0].rows,
            destPlateInfo[0].cols,
            PlatePosition.Destination,
            poolingInternalState.enablePooling
          );
          addDestinationWellMapping(
            dispatch,
            sourceIntIndex,
            poolingInternalState.randomizeWells
              ? applyRandomizeWells(wellMapping, randomizedWells)
              : wellMapping,
            destPlateInfo,
            worklistValues,
            destSelection,
            false
          );
          break;
        case SelectionProcess.ColWise:
          wellMapping = await arrangeColWise(
            currentWorklistValues,
            poolingInternalState.selectedIntToDestWells,
            destSelection[0],
            destPlateInfo[0].rows,
            destPlateInfo[0].cols,
            PlatePosition.Destination,
            poolingInternalState.enablePooling
          );

          addDestinationWellMapping(
            dispatch,
            sourceIntIndex,
            poolingInternalState.randomizeWells
              ? applyRandomizeWells(wellMapping, randomizedWells)
              : wellMapping,
            destPlateInfo,
            worklistValues,
            destSelection,
            false
          );
          break;
        case SelectionProcess.Pool:
          for (const row of poolingInternalState.selectedIntToDestWells) {
            if (row.isSelectable) {
              wellMapping.push({
                sourcePlateIndex: row.plateIndex,
                sourceWellId: row.plateWellId,
                destPlateIndex: destSelection[0].plateIndex,
                destWellId: destSelection[0].plateWellId,
                transferVol: "",
              });
            }
          }
          if (wellMapping.length > 0) {
            addDestinationWellMapping(
              dispatch,
              sourceIntIndex,
              poolingInternalState.randomizeWells
                ? applyRandomizeWells(wellMapping, randomizedWells)
                : wellMapping,
              destPlateInfo,
              worklistValues,
              destSelection,
              false
            );
          }
          break;
      }
      break;
    case SelectionType.SelectOneSourceAndOneDestWell:
      {
        const error = poolingInternalState.enablePooling
          ? false
          : checkForDoubleSelectionWithMultipleDestSelection(
              currentWorklistValues,
              [
                {
                  plateIndex: destSelection[0].plateIndex,
                  plateWellId: destSelection[0].plateWellId,
                  isSelectable: true,
                },
              ]
            );
        if (error) {
          Alert({
            type: AlertType.ERROR,
            message: "Well has already been selected",
          });
          break;
        }
        if (poolingInternalState.selectedIntToDestWells[0].isSelectable) {
          addDestinationWellMapping(
            dispatch,
            sourceIntIndex,
            [
              {
                sourcePlateIndex: sourceIntIndex,
                sourceWellId:
                  poolingInternalState.selectedIntToDestWells[0].plateWellId,
                destPlateIndex: destSelection[0].plateIndex,
                destWellId: destSelection[0].plateWellId,
                transferVol: "",
              },
            ],
            destPlateInfo,
            worklistValues,
            destSelection,
            false
          );
        }
      }
      break;
    case SelectionType.SelectOneSourceAndMultipleDestWells: {
      const error = poolingInternalState.enablePooling
        ? false
        : checkForDoubleSelectionWithMultipleDestSelection(
            currentWorklistValues,
            [
              {
                plateIndex: destSelection[0].plateIndex,
                plateWellId: destSelection[0].plateWellId,
                isSelectable: true,
              },
            ]
          );
      if (error) {
        Alert({
          type: AlertType.ERROR,
          message: "Well has already been selected",
        });
        break;
      }
      for (const row of destSelection) {
        if (poolingInternalState.selectedIntToDestWells[0].isSelectable) {
          wellMapping.push({
            sourcePlateIndex: sourceIntIndex,
            sourceWellId:
              poolingInternalState.selectedIntToDestWells[0].plateWellId,
            destPlateIndex: row.plateIndex,
            destWellId: row.plateWellId,
            transferVol: "",
          });
        }
      }
      if (wellMapping.length > 0) {
        addDestinationWellMapping(
          dispatch,
          sourceIntIndex,
          wellMapping,
          destPlateInfo,
          worklistValues,
          destSelection,
          false
        );
      }
      break;
    }
  }
};

export const getWellStampCount = (allWellsToCheck: string[]) => {
  const wellSet = new Set(allWellsToCheck);
  const wellCount = [];

  for (const well of wellSet) {
    const count = allWellsToCheck.filter((e) => e == well).length;
    wellCount.push({
      well,
      count,
    });
  }

  return wellCount;
};

const checkForIntermediateStampVolumeIssues = (
  wellCount: {
    well: string;
    count: number;
  }[],
  stampVolume: number,
  resuspensionVol: number
) => {
  for (const well of wellCount) {
    if (well.count * stampVolume > resuspensionVol) {
      Alert({
        type: AlertType.ERROR,
        message: "Intermediate Stamped Wells exceed the resuspension volume",
      });

      return false;
    }
  }

  return true;
};

const checkForDestinationStampVolumeIssues = (
  wellCount: {
    well: string;
    count: number;
  }[],
  operatingVol: number,
  startingVol: number,
  stampVol: number
) => {
  for (const well of wellCount) {
    if (operatingVol - startingVol < stampVol * well.count) {
      Alert({
        type: AlertType.ERROR,
        message: "Destination Stamped wells exceed the volume limit",
      });

      return false;
    }
  }

  return true;
};
