import { Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import {
  createPlateMapLayout,
  getWells,
  Note,
  Plate,
} from "features/Plates/components/PlateInfo/helpers";
import { LayoutAction, WellAction } from "features/Plates/state/actions";
import {
  useLayoutStateProvider,
  usePlateStateProvider,
  useWellStateProvider,
} from "features/Plates/state/StateProvider";
import {
  Container,
  GetPlateInfoQuery,
  PlatesCreated,
  useGetPlateInfoQuery,
} from "graphql/generated/schema-types";
import { useGetPlate } from "hooks/cacheAccessHooks";
import React, { useMemo, useState } from "react";
import Loading from "shared-components/Loading";
import styled from "styled-components";
import { SizeMapping } from "./constants";
import { ExportCSVButton } from "./ExportCSV";
import { OverlayFilter } from "./PlateMap/Overlay";
import { PlateMap } from "./PlateMap/PlateMap";
import { PlateNotesTable } from "./PlateNotes";
import { WellListTable } from "./WellList/WellList";

const PlateSection = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  display: grid;
  grid-template-rows: minmax(50px, 8vh) minmax(71px, 12vh) minmax(500px, 73vh);
`;

const Placeholder = styled(Typography)`
  grid-row: 1/4;
  align-self: center;
  justify-self: center;
  text-align: center;
`;

const Header = styled(Typography)`
  grid-row: 1/2;
  align-self: center;
  justify-self: center;
  text-align: center;
`;

const MainContainer = styled(Paper)`
  grid-row: 2/4;
  display: grid;
  grid-template-rows: minmax(82px, 12vh) minmax(500px, 73vh);
  padding-bottom: 5px;
  width: 100%;
`;

const PlateMapFunctionsSection = styled("div")`
  grid-row: 1/2;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 97%;
  padding-bottom: 5px;
  justify-self: center;
`;

const PlateMapListContainer = styled("div")<{
  minwidth: string;
}>`
  grid-row: 2/3;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-evenly;
  overflow: auto;
  max-height: 73vh;
  min-width: ${(props) => props.minwidth};
`;

const LoadingContainer = styled("div")`
  justify-self: center;
  align-self: center;
  grid-row: 2 / end;
`;

export function PlateInfoSection(): JSX.Element {
  const {
    plateState: { selectedPlateBarcode },
  } = usePlateStateProvider();
  const { layoutDispatch } = useLayoutStateProvider();
  const { wellDispatch } = useWellStateProvider();

  const [selectedPlate, setSelectedPlate] = useState<Plate | undefined>();

  const plateCreated = useGetPlate(selectedPlateBarcode) as PlatesCreated;

  const setUpPlate = (getPlateInfo: GetPlateInfoQuery | undefined) => {
    if (
      getPlateInfo &&
      getPlateInfo.plateInfo &&
      (getPlateInfo?.plateInfo?.plateBarcode as string) !==
        selectedPlate?.plateBarcode
    ) {
      const arrayPlateType =
        plateCreated?.arrayPlateType ?? getPlateInfo.plateInfo.arrayPlateType;
      const layoutID =
        arrayPlateType?.plateLayoutTypeId ??
        arrayPlateType?.plateLayoutType?.plateTypeId ??
        arrayPlateType?.defaultLabwareType?.plateLayoutTypeId;

      const plate = createPlateMapLayout(
        {
          plateBarcode: null,
          plateBarcodeAlias: null,
          layout: "",
          numRows: 0,
          numCols: 0,
          wells: [],
          run: null,
          colorOverlayMap: undefined,
          lastScanDate: null,
          notes: undefined,
        },
        layoutID
      ) ?? {
        plateBarcode: null,
        plateBarcodeAlias: null,
        layout: "",
        numRows: 0,
        numCols: 0,
        wells: [],
        run: null,
        colorOverlayMap: undefined,
        lastScanDate: null,
        notes: undefined,
      };
      plate.plateBarcode =
        (plateCreated?.plateBarcode as string) ??
        getPlateInfo.plateInfo.plateBarcode;
      plate.plateBarcodeAlias =
        (plateCreated?.plateBarcodeAlias as string) ??
        getPlateInfo.plateInfo.plateBarcodeAlias;
      plate.lastScanDate =
        (plateCreated?.lastScanDate as string) ??
        getPlateInfo.plateInfo.lastScanDate;
      plate.run =
        (plateCreated?.productionRun?.runName as string) ??
        getPlateInfo.plateInfo.productionRun?.runName;
      plate.notes = plateCreated?.plateNotes?.map(
        (note) =>
          ({
            type: "Plate Note",
            note: note?.note,
            dateCommented: new Date(note?.dateCommented).toLocaleString(),
            user: note?.user?.userName,
          } as Note)
      );
      // console.log("incoming plate layout", plate.numRows, plate.numCols);
      if (plate.numRows && plate.numCols && getPlateInfo.plateInfo.containers) {
        [plate.wells, plate.colorOverlayMap, plate.notes] = getWells(
          getPlateInfo.plateInfo.containers as Container[],
          plate.numRows,
          plate.numCols,
          plate.layout,
          plate.notes ?? []
        );
        layoutDispatch({
          type: LayoutAction.TOGGLE_WELL_LIST,
          payload: {
            wellListOpen: true,
          },
        });
        wellDispatch({
          type: WellAction.TOGGLE_OVERLAY,
          payload: {
            currentOverlay: undefined,
          },
        });
        wellDispatch({
          type: WellAction.TOGGLE_SHOW_INACTIVE,
          payload: {
            showInactive: true,
          },
        });
        // console.log("setting selected plate", plate.plateBarcode);
        setSelectedPlate(plate);
      }
    }
  };

  const getPlateInfo = useGetPlateInfoQuery({
    variables: {
      plateBarcode: selectedPlateBarcode ?? undefined,
    },
    fetchPolicy: "network-only",
    onCompleted() {
      //TODO: Create separate function definition
      console.log("getplateinfo", getPlateInfo);
      setUpPlate(getPlateInfo.data);
    },
  });

  const makePlateSection = useMemo(() => {
    console.log(
      "cached plate:",
      plateCreated,
      "selected plate:",
      selectedPlate,
      "selected platebarcode:",
      selectedPlateBarcode
    );
    // console.log("loading", getPlateInfo.loading);
    return (
      <React.Fragment>
        {selectedPlate === undefined ? (
          <Placeholder variant="h4" fontWeight="bold">
            Select a Plate.
          </Placeholder>
        ) : (
          <PlateSection id={"PlateSection"}>
            {selectedPlate &&
            selectedPlate.plateBarcode === selectedPlateBarcode ? (
              <React.Fragment>
                <Header
                  fontWeight="bold"
                  variant="h6"
                  style={{ height: "100%", width: "100%" }}
                >
                  <Paper
                    style={{
                      height: "99%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    elevation={1}
                  >
                    <Typography variant="h5">
                      Plate: {selectedPlate?.plateBarcode}
                    </Typography>
                    <Typography variant="body1">
                      Date Created:{" "}
                      {format(
                        new Date(plateCreated?.dateCreated),
                        "MM/dd/yy hh:mm aa"
                      )}
                    </Typography>
                  </Paper>
                </Header>
                <MainContainer elevation={1}>
                  <PlateMapFunctionsSection>
                    <OverlayFilter selectedPlate={selectedPlate} />
                    <ExportCSVButton plate={selectedPlate} />
                  </PlateMapFunctionsSection>
                  <PlateMapListContainer
                    minwidth={
                      selectedPlate.layout
                        ? SizeMapping[selectedPlate.layout].sectionWidth
                        : "100px"
                    }
                    id={"PlateMapListContainer"}
                  >
                    <PlateMap selectedPlate={selectedPlate} />
                    <WellListTable selectedPlate={selectedPlate} />
                    {selectedPlate.notes && selectedPlate.notes.length > 0 ? (
                      <PlateNotesTable selectedPlate={selectedPlate} />
                    ) : null}
                  </PlateMapListContainer>
                </MainContainer>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Header
                  variant="h6"
                  fontWeight="bold"
                  color="#cc2828"
                  textAlign="center"
                >
                  Loading Plate: {selectedPlateBarcode}
                </Header>
                <LoadingContainer>
                  <Loading color={"#cc2828"} name={"rotating-plane"} />
                </LoadingContainer>
              </React.Fragment>
            )}
          </PlateSection>
        )}
      </React.Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlate, getPlateInfo.loading]);

  return <React.Fragment>{makePlateSection}</React.Fragment>;
}
