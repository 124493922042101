import {
  ManagePersonalTask,
  PersonalTaskInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { PersonalTaskInputStub } from "mappings/object-stubs/PersonalTaskInput";

export function ManagePersonalTaskInputMapper(
  personalTask: Maybe<ManagePersonalTask>
) {
  const _personalTask = DeepCopy(personalTask);
  const personalTaskInput = _.pick(
    _personalTask,
    _.keys(new PersonalTaskInputStub())
  ) as PersonalTaskInput;
  //These values aren't being set in ManagePersonalTasks but are required fields
  personalTaskInput.taskTypeSelection = 0;
  personalTaskInput.dateSelection = 0;
  personalTaskInput.endRepeatDate = new Date();
  return personalTaskInput;
}
