export enum WorklistToolsInternalAction {
  SET_CTRL_DOWN,
  SET_DEST_SELECTION_PROCESS,
  SET_RANDOMIZE_WELLS,
  SET_ERROR_MESSAGE,
}

export interface IWorklistToolsInternalAction {
  payload: any;
  type: WorklistToolsInternalAction;
}
