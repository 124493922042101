import { useReactiveVar } from "@apollo/client";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import SaveIcon from "@mui/icons-material/Save";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { Divider, Tooltip } from "@mui/material";
import { BackdropOpen } from "App";
import { Methods } from "enums/Methods";
import { NewTemplateNameDialog } from "features/WorklistTools/shared/components/NewTemplateNameDialog";
import {
  StyledIconButton,
  StyledToolbarSection,
} from "features/WorklistTools/shared/components/styles/styled-components";
import { TwoOptionDialog } from "features/WorklistTools/shared/components/TwoOptionDialog";
import { UploadWorklist } from "features/WorklistTools/shared/components/UploadWorklist";
import { WarningDialog } from "features/WorklistTools/shared/components/WarningDialog";
import { ViewWorklistTemplate } from "features/WorklistTools/shared/components/WorklistTemplates/ViewWorklistTemplates";
import { WorklistToolWarnings } from "features/WorklistTools/shared/components/WorklistToolWarnings";
import { useWorklistToolStateProvider } from "features/WorklistTools/shared/state/StateProvider";
import { fillSourcePlateWithColors } from "features/WorklistTools/shared/WorklistHelpers";
import {
  useDeleteExistingMethodReservationMutation,
  useGetExistingMethodReservationLazyQuery,
  useOverwriteExistingWorklistTemplateMutation,
  useSaveWorklistToolTemplateMutation,
  WorklistToolTemplate,
} from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect, useState } from "react";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import { DestPlateInfoState, Twelve24TCToolActions } from "../state";
import { Twelve24TCToolInternalAction } from "../state/action";
import { useGenerateWorklistClick } from "../state/handlers";
import { Twelve24TCToolInternalState } from "../state/initial-state";
import { useTwelve24TCStateProvider } from "../state/StateProvider";
import { buildMethodSettingsWorklist } from "../Twelve24TCToolHelpers";
import {
  get1224TCOptionalWorklistWarnings,
  get1224TCRequiredWorklistWarnings,
} from "../warnings";
import {
  getDeadPlateInfo,
  getDestPlateInfo,
  getHarvestWells,
  getSourcePlateInfo,
  parseMethodSettingsTemplate,
  parseWorklistValues,
} from "./handlers/HandleOpenTemplate";
import {
  parseUploadedWorklist,
  UploadedWorklist,
} from "./handlers/HandleUploadWorklist";

export const GeneralOptions = () => {
  const { worklistToolInternalState } = useWorklistToolStateProvider();
  const dispatch = useAppReduxDispatch();
  const twelve24TCToolInternalState = useReactiveVar(
    Twelve24TCToolInternalState
  );
  const methodSettings = twelve24TCToolInternalState.methodSettings;
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();

  const [requiredWarnings, setRequiredWarnings] = useState([""]);
  const [optionalWarnings, setOptionalWarnings] = useState([""]);
  const [twoOptionDialogOpen, setTwoOptionDialogOpen] = useState(false);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [uploadWorklistOpen, setUploadWorklistOpen] = useState(false);
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [saveWorklistTemplate] = useSaveWorklistToolTemplateMutation();
  const twelve24TCToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present
  );

  const [overwriteExistingWorklistToolTemplate] =
    useOverwriteExistingWorklistTemplateMutation({
      onCompleted() {
        Alert({
          type: AlertType.SUCCESS,
          message: "Template Saved",
        });
      },
    });

  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
      onCompleted() {
        if (!sourcePlateAlreadyReserved) return;
        Alert({
          type: AlertType.WARNING,
          message:
            "Source Plate has already been reserved. You must delete the reservation if you would like to generate a worklist with this plate",
        });
      },
    });

  const [deleteMethodReservation] =
    useDeleteExistingMethodReservationMutation();

  const sourcePlateAlreadyReserved =
    (methodReservation?.existingMethodReservation?.length ?? 0) > 0;

  useEffect(() => {
    getExistingMethodReservation({
      variables: {
        where: {
          isCompleted: { eq: false },
          methodReservationsPlateBarcodes: {
            some: {
              plateBarcode: {
                in: twelve24TCToolInfo.sourcePlateInfo.map(
                  (item) => item.plateBarcode
                ),
              },
            },
          },
        },
      },
    });
  }, [twelve24TCToolInfo.sourcePlateInfo]);

  const handleDeleteMethodReservationClick = () => {
    deleteMethodReservation({
      variables: {
        methodReservationId: methodReservation?.existingMethodReservation
          ? methodReservation!.existingMethodReservation[0]!.methodReservationId
          : 0,
      },
    }).then((res) => {
      getExistingMethodReservation({
        variables: {
          where: {
            isCompleted: { eq: false },
            methodReservationsPlateBarcodes: {
              some: {
                plateBarcode: {
                  eq: twelve24TCToolInfo.sourcePlateInfo[0].plateBarcode,
                },
              },
            },
          },
        },
      });
    });
  };

  const handleSaveTemplateClose = (
    saveTemplate: boolean,
    templateName: string
  ) => {
    setSaveTemplateDialogOpen(false);
    if (!saveTemplate) return;

    // saveWorklistTemplate({
    //   variables: {
    //     input: getTemplateInfoToSave(undefined, templateName),
    //   },
    // }).then((data) => {
    //   Alert({
    //     type: AlertType.SUCCESS,
    //     message: "Template Saved",
    //   });
    // });
  };

  const [overwriteTemplateMode, setOverwriteTemplateMode] = useState(false);
  const handleTwoOptionDialogClose = (rightOption: boolean) => {
    setTwoOptionDialogOpen(false);
    if (rightOption) {
      setTemplateDialogOpen(true);
      setOverwriteTemplateMode(true);
      return;
    }
    setSaveTemplateDialogOpen(true);
  };

  const [uploadingWorklist, setUploadingWorklist] = useState(false);
  useEffect(() => {
    BackdropOpen({
      open: uploadingWorklist,
      header: "Uploading Worklist",
      subText: "Do not reload page",
      size: 60,
    });
  }, [uploadingWorklist]);

  const handleUploadWorklistClose = async (
    uploadWorklist: boolean,
    worklistRows: UploadedWorklist[]
  ) => {
    setUploadWorklistOpen(false);
    if (!uploadWorklist) return;
    setUploadingWorklist(true);
    const parsedWorklistInfo = await parseUploadedWorklist(worklistRows);
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.SET_STATE,
      payload: {
        key: "uploadedWorklist",
        value: true,
      },
    });

    dispatch(
      Twelve24TCToolActions.UPLOAD_WORKLIST_VALUES({
        harvestWells: parsedWorklistInfo.harvestWells,
      })
    );

    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
      payload: {
        methodSettings: parsedWorklistInfo.methodSettings,
      },
    });
    setUploadingWorklist(false);
  };

  const checkForWarnings = async () => {
    const requiredWarning = await get1224TCRequiredWorklistWarnings(
      twelve24TCToolInfo.sourcePlateInfo,
      [twelve24TCToolInfo.destPlateInfo],
      twelve24TCToolInfo.deadPlateType,
      twelve24TCToolInfo.worklistValues,
      twelve24TCToolInternalState,
      worklistToolInternalState
    );
    const optionalWarning = await get1224TCOptionalWorklistWarnings(
      twelve24TCToolInfo.sourcePlateInfo,
      twelve24TCToolInfo.destPlateInfo,
      twelve24TCToolInfo.worklistValues,
      methodSettings
    );
    setRequiredWarnings(requiredWarning);
    setOptionalWarnings(optionalWarning);
  };
  // const [c4SrcWellCount, setC4SrcWellCount] = useState(0);
  // if(Twelve24TCToolInfo.destPlateInfo.)
  const generateWorklist = useGenerateWorklistClick();
  const handleGenerateWorklistClick = () => {
    const warnings = get1224TCOptionalWorklistWarnings(
      twelve24TCToolInfo.sourcePlateInfo,
      twelve24TCToolInfo.destPlateInfo,
      twelve24TCToolInfo.worklistValues,
      methodSettings
    );
    setOptionalWarnings(warnings);
    if (warnings.length > 0) {
      setWarningDialogOpen(true);
    } else if (requiredWarnings.length < 1) {
      console.log("Harvest Wells" + getHarvestWells(twelve24TCToolInfo));
      generateWorklist();
    }
  };

  const { isConfirmed } = useConfirm();
  const handleWarningDialogClose = async (continueAction: boolean) => {
    setWarningDialogOpen(false);
    if (continueAction && requiredWarnings.length < 1) {
      if (
        !methodSettings.dissociation ||
        (methodSettings.dissociation && !methodSettings.washBeforeDissociation)
      ) {
        let warning = "";
        if (!methodSettings.dissociation) {
          warning =
            "Dissociation is disabled. Are you sure you want to continue?";
        } else if (
          methodSettings.dissociation &&
          !methodSettings.washBeforeDissociation
        ) {
          warning =
            "Dissociation Wash is disabled. Are you sure you want to continue?";
        }
        // else if (methodSettings.manualSpin === 1) {
        //   warning = "You’re going to have to manually spin it";
        // }
        const confirmed = await isConfirmed(warning);

        if (!confirmed) {
          return;
        }
      }
      generateWorklist();
    }
  };

  const handleOverwriteTemplateClose = (
    overwriteTemplate: boolean,
    templateInfo: WorklistToolTemplate | undefined
  ) => {
    setTemplateDialogOpen(false);
    if (!overwriteTemplate) {
      setOverwriteTemplateMode(false);
      return;
    }

    // overwriteExistingWorklistToolTemplate({
    //   variables: {
    //     input: getTemplateInfoToSave(templateInfo!.worklistToolTemplateId),
    //   },
    // });

    return;
  };

  const getTemplateInfoToSave = (
    worklistToolTemplateId?: number,
    templateName?: string
  ) => {
    const worklistValues = twelve24TCToolInfo.worklistValues;
    const destPlateState = twelve24TCToolInfo.destPlateInfo;

    const harvestWells = getHarvestWells(twelve24TCToolInfo);

    const worklistMethodSettings = buildMethodSettingsWorklist(
      twelve24TCToolInternalState,
      worklistValues,
      false
    );

    const worklist = [...harvestWells, ...worklistMethodSettings];

    const template = {
      worklistToolTemplateId,
      templateName,
      methodId: 145,
      sourcePlateInfo: getSourcePlateInfo(twelve24TCToolInfo),
      destPlateInfo: getDestPlateInfo(twelve24TCToolInfo),
      deadPlateInfo: getDeadPlateInfo(twelve24TCToolInfo),
      worklistToolTemplateContentsInput: worklist,
    };

    return template;
  };

  const handleTemplateClose = (
    loadTemplate: boolean,
    templateInfo: WorklistToolTemplate | undefined
  ) => {
    setTemplateDialogOpen(false);
    if (!loadTemplate) return;

    const harvestWells = parseWorklistValues(
      templateInfo?.worklistToolTemplateContents?.filter(
        (e) => e?.task === "HarvestWells"
      ) ?? []
    );

    const templateMethodSettings = parseMethodSettingsTemplate(
      templateInfo?.worklistToolTemplateContents
    );

    const sourcePlates = templateInfo?.worklistToolTemplatePlates
      ?.filter((e) => e?.plateType === "Source")
      .map((item) => {
        const rows = [...new Array(item?.numberOfRows)].map(
          (item, index) => index + 1
        );
        const cols = [...new Array(item?.numberOfColumns)].map(
          (item, index) => index + 1
        );
        return {
          plateBarcode: "",
          labwareTypeCode: item?.labwareTypeCode,
          operatingVol: item?.operatingVol ?? 0,
          rows,
          cols,
          wellInfo: fillSourcePlateWithColors(rows, cols),
        };
      });
    const intPlates = templateInfo?.worklistToolTemplatePlates
      ?.filter((e) => e?.plateType === "Int")
      .map((item) => {
        return {
          plateBarcode: "",
          labwareTypeCode: item?.labwareTypeCode,
          operatingVol: item?.operatingVol ?? 0,
          resuspensionVol: item?.resuspensionVol ?? 0,
          rows: [...new Array(item?.numberOfRows)].map(
            (item, index) => index + 1
          ),
          cols: [...new Array(item?.numberOfColumns)].map(
            (item, index) => index + 1
          ),
        };
      });
    const destPlates: DestPlateInfoState[] | undefined =
      templateInfo?.worklistToolTemplatePlates
        ?.filter((e) => e?.plateType === "Dest")
        .map((item) => {
          return {
            index: item!.plateIndex,
            plateBarcode: "",
            labwareTypeCode: item?.labwareTypeCode ?? "",
            operatingVol: item?.operatingVol ?? 0,
            preprocess: item?.topup ?? false,
            topup: item?.preprocess ?? false,
            minOperatingVol: 0,
            startingVol: item?.startingVol ?? 0,
            rows: [...new Array(item?.numberOfRows)].map(
              (item, index) => index + 1
            ),
            cols: [...new Array(item?.numberOfColumns)].map(
              (item, index) => index + 1
            ),
            plateCode: "",
            topupVol: 0,
          };
        });

    const deadPlateType =
      templateInfo?.worklistToolTemplatePlates
        ?.filter((e) => e?.plateType === "Dead")
        .map((item) => item?.labwareTypeCode)[0] ?? "";

    dispatch(
      Twelve24TCToolActions.UPLOAD_PLATES_FROM_TEMPLATE({
        sourcePlates,
        intPlates,
        destPlates,
        deadPlateType,
      })
    );

    setTimeout(() => {
      dispatch(
        Twelve24TCToolActions.UPLOAD_WORKLIST_VALUES_FROM_TEMPLATE({
          harvestWells,
        })
      );
    }, 1000);

    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
      payload: {
        methodSettings: templateMethodSettings,
      },
    });
  };

  return (
    <React.Fragment>
      <StyledToolbarSection>
        <Tooltip
          onOpen={checkForWarnings}
          title={
            sourcePlateAlreadyReserved ? (
              "Source Plate has already been reserved. Please delete the existing reservation"
            ) : (
              <WorklistToolWarnings
                requiredWarnings={requiredWarnings}
                optionalWarnings={optionalWarnings}
              />
            )
          }
          placement="right"
        >
          <div>
            <StyledIconButton
              onClick={handleGenerateWorklistClick}
              disabled={sourcePlateAlreadyReserved}
            >
              <GetAppTwoToneIcon />
            </StyledIconButton>
          </div>
        </Tooltip>
        <StyledIconButton onClick={() => setUploadWorklistOpen(true)}>
          <Tooltip title="Upload Worklist" placement="right">
            <UploadOutlinedIcon />
          </Tooltip>
        </StyledIconButton>
        <StyledIconButton onClick={() => setTemplateDialogOpen(true)}>
          <Tooltip title="Open Template" placement="right">
            <FolderOpenIcon />
          </Tooltip>
        </StyledIconButton>
        <StyledIconButton onClick={() => setTwoOptionDialogOpen(true)}>
          <Tooltip title="Save Template" placement="right">
            <SaveIcon />
          </Tooltip>
        </StyledIconButton>
        <StyledIconButton
          disabled={
            methodReservation?.existingMethodReservation
              ? !(methodReservation?.existingMethodReservation?.length > 0)
              : true
          }
          onClick={() => handleDeleteMethodReservationClick()}
        >
          <Tooltip title="Delete Existing Reservation" placement="right">
            <DeleteForeverIcon />
          </Tooltip>
        </StyledIconButton>
      </StyledToolbarSection>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

      <WarningDialog
        title={"Warning"}
        message={optionalWarnings}
        isOpen={warningDialogOpen}
        handleClose={(continueAction) =>
          handleWarningDialogClose(continueAction)
        }
      />

      <UploadWorklist
        isOpen={uploadWorklistOpen}
        handleClose={(uploadWorklist, worklistRows) =>
          handleUploadWorklistClose(uploadWorklist, worklistRows)
        }
      />

      <TwoOptionDialog
        isOpen={twoOptionDialogOpen}
        handleClose={(rightButton) => handleTwoOptionDialogClose(rightButton)}
        title={"Do you want to overwrite an existing template?"}
        leftButtonText="No"
        rightButtonText="Yes"
      />

      <ConfirmDialog leftButtonText="No" rightButtonText="Yes" />

      <NewTemplateNameDialog
        isOpen={saveTemplateDialogOpen}
        handleClose={(saveTemplate, templateName) =>
          handleSaveTemplateClose(saveTemplate, templateName)
        }
      />

      <ViewWorklistTemplate
        isOpen={templateDialogOpen}
        handleClose={(loadTemplate, templateInfo) =>
          overwriteTemplateMode
            ? handleOverwriteTemplateClose(loadTemplate, templateInfo)
            : handleTemplateClose(loadTemplate, templateInfo)
        }
        overwriteTemplateMode={overwriteTemplateMode}
        methodId={Methods.Twelve24TC}
        // Update this date whenever outputted worklist is changed
        dateWorklistLastUpdated={new Date("09/25/2023 05:00:00 PM")}
      />
    </React.Fragment>
  );
};
