import EditIcon from "@mui/icons-material/Edit";
import { debounce, TextField } from "@mui/material";
import {
  useGetExistingMethodReservationLazyQuery,
  useGetPlateInfoLazyQuery,
  useIsPlateBarcodeValidLazyQuery,
} from "graphql/generated/schema-types";
import { useCallback, useEffect } from "react";
import { PlatePosition } from "../interfaces";
import { WorklistToolsInternalAction } from "../state/action";
import { useWorklistToolStateProvider } from "../state/StateProvider";

interface PlateBarcodeFieldProps {
  plateRole: PlatePosition;
  index: number | undefined;
  label: string;
  plateBarcode: string;
  handlePlateBarcodeChange: (
    index: number | undefined,
    field: any,
    value: string
  ) => void;
  size?: "small" | "medium";
  disabled?: boolean;
  ignoreError?: boolean;
}

export const PlateBarcodeField = ({
  plateRole,
  index,
  label,
  plateBarcode,
  handlePlateBarcodeChange,
  size = "medium",
  disabled,
  ignoreError,
}: PlateBarcodeFieldProps) => {
  const { worklistToolInternalState, worklistToolDispatch } =
    useWorklistToolStateProvider();

  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
    });

  const [getPlateInfo, { data: plateInfo }] = useGetPlateInfoLazyQuery({
    fetchPolicy: "network-only",
  });

  const [checkIfPlateIsValid, { data: isPlateValid }] =
    useIsPlateBarcodeValidLazyQuery({
      onCompleted() {
        const errorMessage = isPlateValid?.isPlateBarcodeValid
          ? ""
          : `${plateRole} Plate Barcode ${
              index === undefined ? "" : index + 1
            } is not in a valid format`;

        worklistToolDispatch({
          type: WorklistToolsInternalAction.SET_ERROR_MESSAGE,
          payload: {
            key: `invalidPlateBarcodeFormat${plateRole}${index}`,
            errorMessage: errorMessage,
          },
        });
      },
    });

  const isPlateValidDebounced = useCallback(
    debounce(checkIfPlateIsValid, 750),
    []
  );

  const plateExists = () => {
    return plateInfo?.plateInfo && plateInfo.plateInfo.plateBarcode !== "";
  };

  const plateExistsInMethodReservation = () => {
    return (
      methodReservation?.existingMethodReservation &&
      methodReservation?.existingMethodReservation?.length > 0
    );
  };

  useEffect(() => {
    if (plateBarcode === "") return;
    isPlateValidDebounced({
      variables: { plateRole: plateRole?.toString() ?? "", plateBarcode },
    });

    getExistingMethodReservation({
      variables: {
        where: {
          methodReservationsPlateBarcodes: {
            some: {
              plateBarcode: {
                eq: plateBarcode,
              },
            },
          },
        },
      },
    });

    getPlateInfo({
      variables: {
        plateBarcode,
      },
    });
  }, [plateBarcode]);

  const plateErrorMessage = () => {
    if (plateBarcode === "") return "";
    if (ignoreError) return "";
    if (plateExists() || plateExistsInMethodReservation())
      return "Plate Barcode Already exists";
    if (isPlateValid !== undefined && !isPlateValid?.isPlateBarcodeValid)
      return "Plate Barcode is not in a valid format";
  };

  const inputProps = {
    startAdornment: index !== undefined ? <b>{index + 1}| </b> : null,
    endAdornment: [
      PlatePosition.Intermediate,
      PlatePosition.Destination,
    ].includes(plateRole) ? (
      <EditIcon></EditIcon>
    ) : null,
  };

  return (
    <TextField
      variant="outlined"
      style={{ width: "100%", marginBottom: "10px" }}
      size={size}
      label={label}
      value={plateBarcode}
      disabled={disabled}
      InputProps={inputProps}
      onChange={(e) =>
        handlePlateBarcodeChange(index, "plateBarcode", e.target.value)
      }
      error={!!plateErrorMessage()}
      helperText={plateErrorMessage()}
    />
  );
};
