import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AutomationMethods } from "enums/Methods";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { PlateBarcodeField } from "features/WorklistTools/shared/components/PlateBarcodeField";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { SelectedSystem } from "features/WorklistTools/shared/components/SelectedSystem";
import { PlatePosition } from "features/WorklistTools/shared/interfaces";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import styled from "styled-components";
import { PoolingNormalizationToolActions } from "../../state";
import { useHandleMethodSettingsUpdated } from "../../state/handlers";
import { usePoolingNormalizationStateProvider } from "../../state/StateProvider";
import { AliquotSettings } from "./components/AliquotSettings";
import { DissociationWashReagent } from "./components/DissociationWashReagent";
import { ExtraPelletWash } from "./components/ExtraPelletWash";
import { HarvestWashReagent } from "./components/HarvestWashReagent";
import { Intermediate96HeadProcessing } from "./components/Intermediate96HeadProcessing";
import { ReducedPelletResuspensionMixSpeed } from "./components/ReducedPelletResuspensionMixSpeed";
import { ReducedVolumeWellWashing } from "./components/ReducedVolumeWellWashing";
import { RefeedWellsReagent } from "./components/RefeedWellsReagent";

const StyledMethodSettingsContainer = styled.div`
  height: 500px;
  overflow: auto;
`;

const StyledMethodSettingsContentContainer = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 15px;
  margin-top: 5px;
  margin-right: 15px;
`;

export function MethodSettings({ deadPlateTypeInfo }: any) {
  const { poolingNormalizationToolInternalState } =
    usePoolingNormalizationStateProvider();
  const poolingState = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present
  );
  const methodSettings = poolingNormalizationToolInternalState.methodSettings;
  const dispatch = useAppReduxDispatch();
  const updateMethodSettings = useHandleMethodSettingsUpdated();

  const handleMethodSettingsChanged = async (key: string, value: any) => {
    switch (key) {
      case "spinParamGForce":
        handleGForceChange(value);
        break;
      case "spinParamPercent":
        handleSpinParamChange(value);
        break;
      case "selectedSystem":
        handleSelectedSystemChange(value);
        break;
    }
    updateMethodSettings(key, value);
  };

  const handleSpinParamChange = (value: string) => {
    const GForceValue = Math.round(0.101 * Math.pow(parseInt(value), 1.999));
    updateMethodSettings("spinParamGForce", GForceValue);
  };

  const handleGForceChange = (value: string) => {
    const quotient = parseInt(value) / 0.101;
    const spinRPM = Math.round(Math.pow(quotient, 1 / 1.999));
    updateMethodSettings("spinParamPercent", spinRPM);
  };

  const handleSelectedSystemChange = (value: number) => {
    let automationMethodId = 0;
    switch (value) {
      case 3:
        automationMethodId = 299;
        break;
      case 10:
        automationMethodId = 300;
        break;
    }
    updateMethodSettings("arraySystemAutomationMethodId", automationMethodId);
  };

  const handleDeadPlateBarcodeChange = (value: string) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_DEAD_PLATEBARCODE({
        value,
      })
    );
  };

  const handleDeadPlateTypeChange = (value: string) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_DEAD_PLATETYPE({
        value,
      })
    );
  };
  return (
    <StyledMethodSettingsContainer>
      <StyledMethodSettingsContentContainer>
        <SelectedSystem
          automationMethodId={AutomationMethods.PoolingNormalization}
          selectedSystem={methodSettings.selectedSystem}
          handleSystemChanged={(system) =>
            handleMethodSettingsChanged("selectedSystem", system)
          }
        />
        <PlateBarcodeField
          plateRole={PlatePosition.Dead}
          index={undefined}
          label="Dead Plate Barcode"
          size="small"
          plateBarcode={poolingState.deadPlateBarcode}
          handlePlateBarcodeChange={(index, field, value) =>
            handleDeadPlateBarcodeChange(value)
          }
        />
        <PlateType
          platePosition={PlatePosition.Dead}
          isDisabled={
            poolingNormalizationToolInternalState.methodSettings!
              .selectedSystem === 0
          }
          acceptedPlateTypes={
            poolingNormalizationToolInternalState.methodSettings
              .int196HeadProcessing
              ? deadPlateTypeInfo
              : deadPlateTypeInfo.filter(
                  (e: any) => e.labwareTypeCode !== "PE_384_FL_L_6057300"
                )
          }
          plateType={poolingState.deadPlateType}
          setPlateType={(plateTypeInfo) =>
            handleDeadPlateTypeChange(plateTypeInfo.labwareTypeCode)
          }
        />
        {poolingState.deadPlateType === "" ? null : (
          <AliquotSettings
            numberOfAliquots={
              poolingNormalizationToolInternalState.methodSettings
                .numberOfAliquots
            }
            aliquotVolume={
              poolingNormalizationToolInternalState.methodSettings.aliquotVolume
            }
            handleMethodSettingsChanged={handleMethodSettingsChanged}
            is96WellPlate={[
              "PE_96_FL_L_6005182",
              "PE_96_FL_L_6055300",
            ].includes(poolingState.deadPlateType)}
          />
        )}
        <NumberField
          size={"small"}
          id="dissociate-time"
          label="Dissociation Time (Minutes)"
          value={
            poolingNormalizationToolInternalState.methodSettings
              .dissociationTime
          }
          onChange={(value) =>
            handleMethodSettingsChanged("dissociationTime", value)
          }
        />
        <NumberField
          size={"small"}
          id="spin-gforce"
          label="Spin Params G Force (RCF)"
          value={
            poolingNormalizationToolInternalState.methodSettings.spinParamGForce
          }
          onChange={(value) =>
            handleMethodSettingsChanged("spinParamGForce", value)
          }
        />
        <NumberField
          size={"small"}
          id="spin-percentage"
          label="Spin Params (%)"
          value={
            poolingNormalizationToolInternalState.methodSettings
              .spinParamPercent
          }
          onChange={(value) =>
            handleMethodSettingsChanged("spinParamPercent", value)
          }
          minValue={1}
          maxValue={100}
        />
        <NumberField
          size={"small"}
          id="spin-time"
          label="Spin Time (Minutes)"
          value={poolingNormalizationToolInternalState.methodSettings.spinTime}
          onChange={(value) => handleMethodSettingsChanged("spinTime", value)}
          minValue={4}
          maxValue={30}
        />
        <NumberField
          size={"small"}
          id="spin-acceleration"
          label="Acceleration Settings (%)"
          value={poolingNormalizationToolInternalState.methodSettings.spinAccel}
          onChange={(value) => handleMethodSettingsChanged("spinAccel", value)}
          minValue={1}
          maxValue={100}
        />
        <NumberField
          size={"small"}
          id="spin-deceleration"
          label="Deceleration Settings (%)"
          value={poolingNormalizationToolInternalState.methodSettings.spinDecel}
          onChange={(value) => handleMethodSettingsChanged("spinDecel", value)}
          minValue={1}
          maxValue={100}
        />

        <DissociationWashReagent
          poolingInternalState={poolingNormalizationToolInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />

        <RefeedWellsReagent
          poolingInternalState={poolingNormalizationToolInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />

        <HarvestWashReagent
          poolingInternalState={poolingNormalizationToolInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />
        {/* Remove comments to add Source96HeadProcessing Logic */}
        {/* <Source96HeadProcessing
          poolingInternalState={poolingInternalState}
          poolingState={poolingState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        /> */}

        <Intermediate96HeadProcessing
          poolingInternalState={poolingNormalizationToolInternalState}
          poolingState={poolingState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
          handleDeadPlateTypeChange={handleDeadPlateTypeChange}
        />

        <ReducedVolumeWellWashing
          poolingInternalState={poolingNormalizationToolInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />

        <ReducedPelletResuspensionMixSpeed
          poolingInternalState={poolingNormalizationToolInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />

        <ExtraPelletWash
          poolingInternalState={poolingNormalizationToolInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={
                poolingNormalizationToolInternalState.methodSettings
                  .dissociation
              }
              onChange={(e) =>
                handleMethodSettingsChanged("dissociation", e.target.checked)
              }
              name="checkedB"
              color="primary"
              size="small"
            />
          }
          label="Dissociation"
        />
      </StyledMethodSettingsContentContainer>
    </StyledMethodSettingsContainer>
  );
}
