import {
  getRowAndColCountByLabwareType,
  getSourcePlateInfo,
} from "features/WorklistTools/shared/WorklistHelpers";
import { DestPlateInfoState, SourcePlateInfoState } from "../../state";
import { getSourcePlateInfoState } from "../../state-helpers";
import { ITwelve24TCMethodSettings } from "../../state/initial-state";

export interface UploadedWorklist {
  task: string;
  details: string;
  aspiratevol: string;
  dispensevol: string;
  transfervol: string;
  sourceplatetype: string;
  sourceplatebarcode: string;
  sourcewellid: string;
  destinationplatetype: string;
  destinationplatebarcode: string;
  destinationwellid: string;
  rownumber: string;
}

export const parsePlateInfo = (
  uploadedWorklist: UploadedWorklist[],
  key: keyof UploadedWorklist
) => {
  return [
    ...new Map(uploadedWorklist.map((rows) => [rows[key], rows])).values(),
  ];
};

const mapWorklistRows = (
  row: UploadedWorklist,
  sourcePlateIndex: number,
  destPlateIndex: number
) => {
  return {
    sourcePlateIndex: sourcePlateIndex,
    sourceWellId: row.sourcewellid,
    destPlateIndex: destPlateIndex,
    destWellId: row.destinationwellid,
    transferVol: row.transfervol ?? "",
  };
};

export const parseUploadedWorklist = async (
  worklistRows: UploadedWorklist[]
) => {
  const harvestWellsRows = worklistRows.filter(
    (e) => e.task === "HarvestWells"
  );

  const sourcePlateInfo: SourcePlateInfoState[] = Array.from(
    { length: 8 },
    () => getSourcePlateInfoState()
  );

  let destPlateInfo: DestPlateInfoState = {
    plateBarcode: "",
    labwareTypeCode: "",
    operatingVol: 0,
    minOperatingVol: 0,
    startingVol: 0,
    rows: [],
    cols: [],
    preprocess: false,
    plateCode: "",
    topupVol: 0,
  };
  const uniqueSourcePlates = parsePlateInfo(
    harvestWellsRows,
    "sourceplatebarcode"
  );

  const uniqueDestinationPlates = parsePlateInfo(
    harvestWellsRows,
    "destinationplatebarcode"
  );

  for (const [index, row] of uniqueSourcePlates.entries()) {
    const wellInfo = await getSourcePlateInfo(0, row.sourceplatebarcode, false);
    const rowColCount = getRowAndColCountByLabwareType(row.sourceplatetype);
    const sourceLabwareOperating = worklistRows.find(
      (e) =>
        e.task === "SourceLabwareOperating" &&
        e.destinationplatetype === row.destinationplatetype
    );
    sourcePlateInfo[index] = {
      plateBarcode: row.sourceplatebarcode,
      labwareTypeCode: row.sourceplatetype,
      operatingVol: parseInt(sourceLabwareOperating?.details ?? "0"),
      rows: rowColCount.rows,
      cols: rowColCount.cols,
      wellInfo: wellInfo,
      discardSourcePlate: -1, //TEMPORARY
    };
  }

  for (const [index, row] of uniqueDestinationPlates.entries()) {
    const rowColCount = getRowAndColCountByLabwareType(
      row.destinationplatetype
    );
    const destinationLabwareOperating = worklistRows.find(
      (e) =>
        e.task === "DestinationLabwareOperating" &&
        e.destinationplatetype === row.destinationplatetype
    );
    destPlateInfo = {
      plateBarcode: row.destinationplatebarcode,
      labwareTypeCode: row.destinationplatetype,
      operatingVol: parseInt(destinationLabwareOperating?.details ?? "0"),
      minOperatingVol: 0,
      startingVol: parseInt(destinationLabwareOperating?.transfervol ?? "0"),
      rows: rowColCount.rows,
      cols: rowColCount.cols,
      preprocess: Boolean(destinationLabwareOperating?.aspiratevol),
      plateCode: "",
      topupVol: 0,
    };
  }

  const deadPlate = worklistRows.find((row) => row.task === "DeadTotalPlate");

  const deadPlateBarcode = deadPlate?.sourceplatebarcode;
  const deadPlateType = deadPlate?.sourceplatetype;

  const harvestWells = harvestWellsRows.map((row) =>
    mapWorklistRows(
      row,
      sourcePlateInfo.findIndex(
        (plate) => plate.plateBarcode === row.sourceplatebarcode
      ),
      0
    )
  );

  const methodSettings = parseMethodSettingsFromWorklist(worklistRows);

  const parsedWorklistInfo = {
    harvestWells,
    sourcePlateInfo,
    destPlateInfo,
    deadPlateBarcode,
    deadPlateType,
    methodSettings,
  };

  return parsedWorklistInfo;
};

const parseMethodSettingsFromWorklist = (
  uploadedWorklist: UploadedWorklist[]
) => {
  const getWorklistRowByTask = (task: string) =>
    uploadedWorklist.find((e) => e.task === task);

  const calculateSpinParamGForce = (spinParamPercent: number) =>
    Math.round(0.101 * Math.pow(spinParamPercent, 1.999));

  const getTaskDetails_int = (task: string, nullCoalesceValue = "0") =>
    parseInt(getWorklistRowByTask(task)?.details ?? nullCoalesceValue);

  const getTaskDetails_bool = (task: string) =>
    Boolean(getTaskDetails_int(task));

  const getTaskTransferVol = (task: string) =>
    parseInt(getWorklistRowByTask(task)?.transfervol ?? "0");

  const methodSettings: ITwelve24TCMethodSettings = {
    selectedSystem: 4,
    arraySystemAutomationMethodId: 391,
    aliquotVolume: getTaskTransferVol("DeadTotalPlate"),
    dissociationTime: getTaskDetails_int("IncubationTime") / 60,
    spinParamGForce: calculateSpinParamGForce(
      getTaskDetails_int("SpinParameters")
    ),
    spinParamPercent: getTaskDetails_int("SpinParameters"),
    spinTime:
      parseInt(getWorklistRowByTask("SpinParameters")?.aspiratevol ?? "0") / 60,
    spinAccel: parseInt(
      getWorklistRowByTask("SpinParameters")?.dispensevol ?? "0"
    ),
    spinDecel: getTaskTransferVol("SpinParameters"),
    dissociationWashRGT: getTaskDetails_int("DissociationWashRGT"),
    pelletResuspensionRGT: getTaskDetails_int("PelletResuspensionRGT"),
    manualSpin: getTaskDetails_int("ManualSpin"),
    dissociation: getTaskDetails_bool("Dissociation"),
    defaultVSpinSettings: getTaskDetails_bool("defaultVSpinSettings"),
    washBeforeDissociation: getTaskDetails_bool("DissociationWash"),
    pelletResuspensionVol: getTaskTransferVol("PelletResuspensionVol"),
  };

  return methodSettings;
};
