import { gql } from "@apollo/client";
import {
  ContainerTableEntry,
  Plate,
  PlateCreated,
  PlateInfo,
  Sample,
} from "graphql/fragments/plate.fragments";
import { TypeNames } from "../../enums/TypeNames";

export const PlateQueries = {
  remote: {
    IS_PLATEBARCODE_VALID: gql`
      query isPlateBarcodeValid($plateRole: String!, $plateBarcode: String!) {
        isPlateBarcodeValid(plateRole: $plateRole, plateBarcode: $plateBarcode)
      }
    `,
    GET_UNAVAILABLE_PLATES: gql`
      query getUnavailablePlates($methodId: Int!) {
        unavailablePlates(methodId: $methodId)
      }
    `,
    GET_CELIGO_PLATE_METADATA: gql`
      query getCeligoPlateMetadata {
        celigoScanPlateMetadata {
          prefix
          plates {
            platesCreated {
              ...PlateCreated
            }
            metadata {
              sourcePlateBarcode
              analysisSetting
              platePitch
              imageExportFormat
              imageExportSetting
              experimentSetting
            }
          }
        }
      }
      ${PlateCreated}
    `,
    GET_PLATES: gql`
      query getPlates(
        $pageSize: Int
        $after: String
        $where: PlatesCreatedFilterInput
        $order: [PlatesCreatedSortInput!]
      ) {
        plates(
          first: $pageSize

          after: $after
          where: $where
          order: $order
        ) {
          edges {
            node {
              ...Plate
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
      ${Plate}
    `,
    GET_PLATE_INFO: gql`
      query getPlateInfo($plateBarcode: String) {
        plateInfo(plateBarcode: $plateBarcode) {
          ...PlateInfo
        }
      }
      ${PlateInfo}
    `,
    GET_TUBE_BARCODES_FOR_AUTOCOMPLETE: gql`
      query GetTubeBarcodesForAutocomplete($search: String!) {
        containers(
          where: {
            and: [
              { barcode: { neq: null } }
              { barcode: { contains: $search } }
            ]
          }
          order: { containerId: DESC }
        ) {
          edges {
            node {
              containerId
              barcode
            }
          }
        }
      }
    `,
    GET_PLATES_FOR_AUTOCOMPLETE: gql`
      query GetPlateForAutocomplete($search: String!) {
        plates(
          where: { plateBarcode: { contains: $search } }
          order: { plateCreatedId: DESC }
        ) {
          edges {
            node {
              plateCreatedId
              plateBarcode
            }
          }
        }
      }
    `,
    GET_LOT_NUMBERS: gql`
      query GetLotNumbers($search: String!) {
        lotNumbers(
          where: { lotNumber1: { contains: $search } }
          order: { lotNumberId: DESC }
        ) {
          edges {
            node {
              lotNumberId
              lotNumber1
            }
          }
        }
      }
    `,
    GET_CONTAINER_LINEAGE: gql`
      query getContainerLineage($containerId: Int!) {
        containerLineage(containerId: $containerId) {
          ...ContainerLineage
        }
      }
    `,
    GET_CONTAINERS: gql`
      query GetContainers(
        $pageSize: Int
        $after: String
        $order: [ContainerSortInput!]
        $where: ContainerFilterInput
      ) {
        containers(
          first: $pageSize
          after: $after
          order: $order
          where: $where
        ) {
          edges {
            node {
              ...ContainerTableEntry
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
      ${ContainerTableEntry}
    `,
  },
  local: {
    GET_SAMPLE: gql`
            query getSample($sampleId: Int!){
                sample(id: $sampleId, typeName: "${TypeNames.Sample}", keyField: "sampleId") @client {
                    ...Sample
                }
            }
            ${Sample}
        `,
    GET_PlATE_BY_ID: gql`
            query GetPlateById($plateBarcode: String!){
                plate(id: $plateBarcode, typeName: "${TypeNames.PlatesCreated}", keyField: "plateBarcode") @client {
                    ...Plate
                }
            }
            ${Plate}
        `,
    GET_CONTAINER_BY_ID: gql`
            query GetContainerById($containerId: Int!) {
                container(id: $containerId, typeName: "${TypeNames.Container}", keyField: "containerId") @client {
                    ...ContainerTableEntry
                }
            }
            ${ContainerTableEntry}
        `,
  },
};
