import { PlatePosition } from "features/WorklistTools/shared/interfaces";
import { is384WPlate } from "features/WorklistTools/shared/PlateHelpers";
import {
  applyStampShapeToSource,
  getRowAndColCountByLabwareType,
  getSourcePlateInfo,
  stampSourceToDestination,
  stampTo384WDestination,
} from "features/WorklistTools/shared/WorklistHelpers";
import {
  DestPlateInfoState,
  IntermediatePlateInfoState,
  IStampTopLeftWorklistState,
  SourcePlateInfoState,
} from "../../state";
import {
  getIntermediatePlateInfoState,
  getSourcePlateInfoState,
} from "../../state-helpers";
import { IPoolingMethodSettings } from "../../state/initial-state";

export interface UploadedWorklist {
  task: string;
  details: string;
  aspiratevol: string;
  dispensevol: string;
  transfervol: string;
  sourceplatetype: string;
  sourceplatebarcode: string;
  sourcewellid: string;
  destinationplatetype: string;
  destinationplatebarcode: string;
  destinationwellid: string;
  rownumber: string;
}

export const parsePlateInfo = (
  uploadedWorklist: UploadedWorklist[],
  key: keyof UploadedWorklist
) => {
  return [
    ...new Map(uploadedWorklist.map((rows) => [rows[key], rows])).values(),
  ];
};

const mapWorklistRows = (
  row: UploadedWorklist,
  sourcePlateIndex: number,
  destPlateIndex: number
) => {
  return {
    sourcePlateIndex: sourcePlateIndex,
    sourceWellId: row.sourcewellid,
    destPlateIndex: destPlateIndex,
    destWellId: row.destinationwellid,
    transferVol: row.transfervol ?? "",
  };
};

const mapStampTopLeft = (row: UploadedWorklist, destPlateIndex: number) => {
  return {
    sourceWellId: row.sourcewellid,
    destPlateIndex: destPlateIndex,
    destWellId: row.destinationwellid,
  };
};

export const parseUploadedWorklist = async (
  worklistRows: UploadedWorklist[]
) => {
  const harvestWellsRows = worklistRows.filter(
    (e) => e.task === "HarvestWells"
  );
  const int1ToInt2Rows = worklistRows.filter((e) => e.task === "Int1ToInt2");
  const int2ToInt3Rows = worklistRows.filter((e) => e.task === "Int2ToInt3");
  const int1ToInt3Rows = worklistRows.filter((e) => e.task === "Int1ToInt3");
  const int1StampTopLeftRows = worklistRows.filter(
    (e) => e.task === "Int1StampTopLeft"
  );
  const int2StampTopLeftRows = worklistRows.filter(
    (e) => e.task === "Int2StampTopLeft"
  );
  const int3StampTopLeftRows = worklistRows.filter(
    (e) => e.task === "Int3StampTopLeft"
  );
  const int1ToDestRows = worklistRows.filter((e) => e.task === "Int1ToDest");
  const int2ToDestRows = worklistRows.filter((e) => e.task === "Int2ToDest");
  const int3ToDestRows = worklistRows.filter((e) => e.task === "Int3ToDest");

  const sourcePlateInfo: SourcePlateInfoState[] = Array.from(
    { length: 8 },
    () => getSourcePlateInfoState()
  );
  const intPlateInfo: IntermediatePlateInfoState[] = Array.from(
    { length: 3 },
    () => getIntermediatePlateInfoState()
  );
  const destPlateInfo: DestPlateInfoState[] = [
    {
      index: 0,
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      minOperatingVol: 0,
      startingVol: 0,
      rows: [],
      cols: [],
      preprocess: false,
      topup: false,
    },
  ];

  const uniqueSourcePlates = parsePlateInfo(
    harvestWellsRows,
    "sourceplatebarcode"
  );

  const uniqueIntermediatePlates = worklistRows.filter((e) =>
    /^Intermediate[1-3]Resuspension$/.test(e.task)
  );

  const uniqueDestinationPlates = parsePlateInfo(
    [
      ...int1ToDestRows,
      ...int2ToDestRows,
      ...int3ToDestRows,
      ...int1StampTopLeftRows,
      ...int2StampTopLeftRows,
      ...int3StampTopLeftRows,
    ],
    "destinationplatebarcode"
  );

  for (const [index, row] of uniqueSourcePlates.entries()) {
    const wellInfo = await getSourcePlateInfo(0, row.sourceplatebarcode, false);
    const rowColCount = getRowAndColCountByLabwareType(row.sourceplatetype);
    const sourceLabwareOperating = worklistRows.find(
      (e) =>
        e.task === "SourceLabwareOperating" &&
        e.destinationplatetype === row.destinationplatetype
    );
    const discardSourcePlate = parseInt(
      worklistRows.find(
        (e) =>
          e.task === "DiscardSourcePlate" &&
          e.sourceplatebarcode === row.sourceplatebarcode
      )?.details ?? "-1"
    );
    sourcePlateInfo[index] = {
      plateBarcode: row.sourceplatebarcode,
      labwareTypeCode: row.sourceplatetype,
      operatingVol: parseInt(sourceLabwareOperating?.details ?? "0"),
      rows: rowColCount.rows,
      cols: rowColCount.cols,
      wellInfo: wellInfo,
      discardSourcePlate: discardSourcePlate,
    };
  }

  for (const [index, row] of uniqueIntermediatePlates.entries()) {
    const labwareTypeCode = worklistRows.find(
      (e) => e.sourceplatebarcode === row.sourceplatebarcode
    )?.sourceplatetype;

    intPlateInfo[index] = {
      plateBarcode: row.sourceplatebarcode,
      labwareTypeCode: labwareTypeCode ?? "",
      rows: [1, 2, 3, 4, 5, 6, 7, 8],
      cols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      operatingVol: 0,
      resuspensionVol: parseInt(row?.details ?? "0"),
    };
  }

  for (const [index, row] of uniqueDestinationPlates.entries()) {
    const rowColCount = getRowAndColCountByLabwareType(
      row.destinationplatetype
    );
    const destinationLabwareOperating = worklistRows.find(
      (e) =>
        e.task === "DestinationLabwareOperating" &&
        e.destinationplatetype === row.destinationplatetype
    );
    destPlateInfo[index] = {
      index: index,
      plateBarcode: row.destinationplatebarcode,
      labwareTypeCode: row.destinationplatetype,
      operatingVol: parseInt(destinationLabwareOperating?.details ?? "0"),
      minOperatingVol: 0,
      startingVol: parseInt(destinationLabwareOperating?.transfervol ?? "0"),
      rows: rowColCount.rows,
      cols: rowColCount.cols,
      preprocess: Boolean(destinationLabwareOperating?.aspiratevol),
      topup: Boolean(destinationLabwareOperating?.dispensevol),
    };
  }

  const deadPlate = worklistRows.find((row) => row.task === "DeadTotalPlate");

  const deadPlateBarcode = deadPlate?.sourceplatebarcode;
  const deadPlateType = deadPlate?.sourceplatetype;

  const harvestWells = harvestWellsRows.map((row) =>
    mapWorklistRows(
      row,
      sourcePlateInfo.findIndex(
        (plate) => plate.plateBarcode === row.sourceplatebarcode
      ),
      intPlateInfo.findIndex(
        (e) => e.plateBarcode === row.destinationplatebarcode
      )
    )
  );

  const int1ToInt2 = int1ToInt2Rows.map((row) => mapWorklistRows(row, 0, 1));
  const int2ToInt3 = int2ToInt3Rows.map((row) => mapWorklistRows(row, 1, 2));
  const int1ToInt3 = int1ToInt3Rows.map((row) => mapWorklistRows(row, 0, 2));

  const getStampDestinationPlateIndex = (destinationPlateBarcode: string) => {
    return destPlateInfo.findIndex(
      (e) => e.plateBarcode === destinationPlateBarcode
    );
  };

  const int1StampTopLeft = int1StampTopLeftRows.map((row) =>
    mapStampTopLeft(
      row,
      getStampDestinationPlateIndex(row.destinationplatebarcode)
    )
  );
  const int2StampTopLeft = int2StampTopLeftRows.map((row) =>
    mapStampTopLeft(
      row,
      getStampDestinationPlateIndex(row.destinationplatebarcode)
    )
  );
  const int3StampTopLeft = int3StampTopLeftRows.map((row) =>
    mapStampTopLeft(
      row,
      getStampDestinationPlateIndex(row.destinationplatebarcode)
    )
  );

  const getDestPlateIndex = (row: UploadedWorklist) =>
    uniqueDestinationPlates.findIndex(
      (e) => e.destinationplatebarcode === row.destinationplatebarcode
    );
  const int1ToDest = int1ToDestRows.map((row) =>
    mapWorklistRows(row, 0, getDestPlateIndex(row))
  );
  const int2ToDest = int2ToDestRows.map((row) =>
    mapWorklistRows(row, 1, getDestPlateIndex(row))
  );
  const int3ToDest = int3ToDestRows.map((row) =>
    mapWorklistRows(row, 2, getDestPlateIndex(row))
  );

  const referenceStampShape = parseReferenceStampShape(
    worklistRows.find((e) => e.task === "ReferenceStampShape")?.sourcewellid ??
      ""
  );

  const stampTopLeftTransfers = buildStampTopLeftTransfers(
    referenceStampShape,
    int1StampTopLeft,
    int2StampTopLeft,
    int3StampTopLeft,
    destPlateInfo
  );

  const methodSettings = parseMethodSettingsFromWorklist(worklistRows);

  const parsedWorklistInfo = {
    harvestWells,
    int1ToInt2,
    int2ToInt3,
    int1ToInt3,
    int1StampTopLeft,
    int2StampTopLeft,
    int3StampTopLeft,
    int1ToDest,
    int2ToDest,
    int3ToDest,
    sourcePlateInfo,
    intPlateInfo,
    destPlateInfo,
    deadPlateBarcode,
    deadPlateType,
    referenceStampShape,
    stampTopLeftTransfers,
    methodSettings,
  };

  return parsedWorklistInfo;
};

const parseReferenceStampShape = (refStampShape: string) => {
  const stampWells = [];

  const adjacentWells = refStampShape.split(";");
  adjacentWells.pop(); //last index is an empty string so pop it off the array;

  for (const well of adjacentWells) {
    const adjacentWellsRange = well.split(":");

    if (adjacentWellsRange.length <= 1) {
      stampWells.push(well);
      continue;
    }

    // containsAdjacentWellsRange
    const getCol = (i: number) => parseInt(adjacentWellsRange[i].substring(1));
    for (let i = getCol(0); i <= getCol(1); i++) {
      const row = well.charAt(0);
      const calculatedWell = `${row}${i}`;
      stampWells.push(calculatedWell);
    }
  }

  return stampWells;
};

const buildStampTopLeftTransfers = (
  referenceStampShape: string[],
  int1StampTopLeft: IStampTopLeftWorklistState[],
  int2StampTopLeft: IStampTopLeftWorklistState[],
  int3StampTopLeft: IStampTopLeftWorklistState[],
  destPlateInfo: DestPlateInfoState[]
) => {
  const stampTopLeftTransfers = [];
  const allStamps = [int1StampTopLeft, int2StampTopLeft, int3StampTopLeft];
  for (const [index, stampTopLeft] of allStamps.entries()) {
    for (const stamp of stampTopLeft) {
      const wellSelection = {
        plateIndex: index,
        plateWellId: stamp.sourceWellId,
        isSelectable: true,
      };
      const sourceStamp = applyStampShapeToSource(
        wellSelection,
        referenceStampShape,
        true
      );

      const destWellSelection = {
        plateIndex: stamp.destPlateIndex,
        plateWellId: stamp.destWellId,
        isSelectable: true,
      };

      const destPlateState = destPlateInfo[destWellSelection.plateIndex];

      const mapping = is384WPlate(
        destPlateState.rows.length,
        destPlateState.cols.length
      )
        ? stampTo384WDestination(
            [],
            sourceStamp,
            destWellSelection,
            PlatePosition.Destination,
            true,
            true
          )
        : stampSourceToDestination(
            [],
            sourceStamp,
            destWellSelection,
            PlatePosition.Destination,
            true,
            true
          );

      stampTopLeftTransfers.push(...mapping);
    }
  }

  return stampTopLeftTransfers;
};

const parseMethodSettingsFromWorklist = (
  uploadedWorklist: UploadedWorklist[]
) => {
  const getWorklistRowByTask = (task: string) =>
    uploadedWorklist.find((e) => e.task === task);

  const calculateSpinParamGForce = (spinParamPercent: number) =>
    Math.round(0.101 * Math.pow(spinParamPercent, 1.999));

  const getTaskDetails_int = (task: string, nullCoalesceValue = "0") =>
    parseInt(getWorklistRowByTask(task)?.details ?? nullCoalesceValue);

  const getTaskDetails_bool = (task: string) =>
    Boolean(getTaskDetails_int(task));

  const getTaskTransferVol = (task: string) =>
    parseInt(getWorklistRowByTask(task)?.transfervol ?? "0");

  const methodSettings: IPoolingMethodSettings = {
    selectedSystem: 3,
    arraySystemAutomationMethodId: 299, //ASAM for Pooling Method on System 3
    numberOfAliquots: getTaskDetails_int("DeadTotalPlate"),
    aliquotVolume: getTaskTransferVol("DeadTotalPlate"),
    dissociationTime: getTaskDetails_int("IncubationTime") / 60,
    spinParamGForce: calculateSpinParamGForce(
      getTaskDetails_int("SpinParameters")
    ),
    spinParamPercent: getTaskDetails_int("SpinParameters"),
    spinTime:
      parseInt(getWorklistRowByTask("SpinParameters")?.aspiratevol ?? "0") / 60,
    spinAccel: parseInt(
      getWorklistRowByTask("SpinParameters")?.dispensevol ?? "0"
    ),
    spinDecel: getTaskTransferVol("SpinParameters"),
    dissociationWashRGT: getTaskDetails_int("DissociationWashRGT"),
    reFeedWellsRGT: getTaskDetails_int("ReFeedWellsRGT"),
    harvestWashRGT: getTaskDetails_int("HarvestWashRGT"),
    extraPelletWash: getTaskDetails_bool("ExtraPelletWash"),
    dissociation: getTaskDetails_bool("Dissociation"),
    washBeforeDissociation: getTaskDetails_bool("DissociationWash"),
    washAfterDissociation: getTaskDetails_bool("HarvestWash"),
    stampReuseTips: getTaskDetails_bool("StampReuseTips"),
    reFeedSourceWells: getTaskDetails_bool("ReFeedWells"),
    harvestWash: getTaskDetails_bool("WashAfterDissociation"),
    stampVolume: getTaskDetails_int("StampVolume"),
    groupedSeedingPriority: [
      { id: "12/24W", content: "12/24W Group" },
      { id: "96/384W", content: "96/384W Group" },
    ],
    seedingPriority_12_24_Well: get_12_24_SeedingPriority(uploadedWorklist),
    seedingPriority_96_384_Well: get_96_384_SeedingPriority(uploadedWorklist),
    int196HeadProcessing: getTaskDetails_bool("Int196HeadProcessing"),
    reducedVolWellWash: getTaskDetails_bool("ReducedVolWellWash"),
    reducedPelResusSpeed: getTaskDetails_bool("ReducedPelResusSpeed"),
    int1CountAliquot: getTaskDetails_int("Int1CountAliquot", "-1"),
    int1SpinResuspend: getTaskDetails_int("Int1SpinResuspend", "-1"),
  };

  return methodSettings;
};

const getPlateLayoutType = (labwareType: string) => {
  const labwareTypeSplit = labwareType.split("_");
  const plateLayoutType = labwareTypeSplit[1];

  return plateLayoutType;
};

const get_12_24_SeedingPriority = (uploadedWorklist: UploadedWorklist[]) => {
  const seedingPriority = uploadedWorklist.filter(
    (e) => e.task === "SeedingPriority"
  );

  const seedingPriority_12_24_Well = seedingPriority.filter(
    (e) =>
      getPlateLayoutType(e.destinationplatetype) === "12" ||
      getPlateLayoutType(e.destinationplatetype) === "24"
  );

  return seedingPriority_12_24_Well.map((item) => item.destinationplatetype);
};

const get_96_384_SeedingPriority = (uploadedWorklist: UploadedWorklist[]) => {
  const seedingPriority = uploadedWorklist.filter(
    (e) => e.task === "SeedingPriority"
  );

  const seedingPriority_96_384_Well = seedingPriority.filter(
    (e) =>
      getPlateLayoutType(e.destinationplatetype) === "96" ||
      getPlateLayoutType(e.destinationplatetype) === "384"
  );

  return seedingPriority_96_384_Well.map((item) => item.destinationplatetype);
};
