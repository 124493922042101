import {
  IWorklistToolsInternalAction,
  WorklistToolsInternalAction,
} from "./action";
import { IWorklistToolInternalState } from "./initial-state";

export function WorklistToolInternalReducer(
  state: IWorklistToolInternalState,
  action: IWorklistToolsInternalAction
) {
  switch (action.type) {
    case WorklistToolsInternalAction.SET_CTRL_DOWN:
      return {
        ...state,
        setCtrlDown: action.payload.setCtrlDown,
      };
    case WorklistToolsInternalAction.SET_DEST_SELECTION_PROCESS:
      return {
        ...state,
        destSelectionProcess: action.payload.destSelectionProcess,
      };
    case WorklistToolsInternalAction.SET_RANDOMIZE_WELLS:
      return {
        ...state,
        randomizeWells: action.payload.randomizeWells,
      };
    case WorklistToolsInternalAction.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          [action.payload.key]: action.payload.errorMessage,
        },
      };
  }
}
