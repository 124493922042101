import { isMatrixRack } from "../shared/PlateHelpers";
import { IWorklistToolInternalState } from "../shared/state/initial-state";
import { checkForDuplicateBarcodes } from "../shared/WorklistHelpers";
import {
  disableDestPlateTypeSelectionMsg,
  UploadASrcPlateB4YouDownloadAWorklist,
} from "./plates/handlers/uploadPlateButtonDisabled";
import { DestPlateInfoState, WorklistValuesState } from "./state";
import {
  ITwelve24TCMethodSettings,
  ITwelve24TCToolInternalState,
} from "./state/initial-state";
import {
  currentWellSplits,
  topupCalc,
  topUpNotZero,
  topUpPresent,
  userShouldManuallySpin,
} from "./Twelve24TCToolHelpers";

export const get1224TCRequiredWorklistWarnings = (
  sourcePlateInfo: any,
  destPlateInfo: DestPlateInfoState[],
  deadPlateType: string,
  worklistValues: any,
  internalState: ITwelve24TCToolInternalState,
  worklistToolState: IWorklistToolInternalState
) => {
  let warning: string[] = [];
  const methodSettings = internalState.methodSettings;

  const generalErrors = Object.values(worklistToolState.errorMessages).filter(
    (message) => message !== ""
  );
  const requiredMessages = checkForRequiredMethodSettings(methodSettings);
  const vSpingRangeErrors = checkVSpinRange(methodSettings);

  warning = [...generalErrors, ...requiredMessages, ...vSpingRangeErrors];

  if (
    destPlateInfo.some(
      (plate) => isMatrixRack(plate.labwareTypeCode) && plate.operatingVol > 900
    )
  ) {
    warning.push("Destination Matrix Rack Operating Vol must be less than 900");
  }

  const PRVAndBarcodeErrMessages = disableDestPlateTypeSelectionMsg(
    internalState,
    destPlateInfo[0]
  );
  for (let i = 0; i < PRVAndBarcodeErrMessages.length; i++) {
    if (PRVAndBarcodeErrMessages[i] != "Need System Selected")
      warning.push(PRVAndBarcodeErrMessages[i]);
  }

  //TODO Does there need to be a warning for the when the dissociation time === 0?
  if (methodSettings.dissociation && methodSettings.dissociationTime! === 0) {
    warning.push("Dissociation Time is a required field");
  }
  if (
    methodSettings.dissociation &&
    methodSettings.dissociationTime &&
    (methodSettings.dissociationTime! < 1 ||
      methodSettings.dissociationTime! > 60)
  ) {
    warning.push("Dissociation Time must be between [1,60]");
  }

  for (let i = 0; i < sourcePlateInfo.length; i++) {
    if (
      sourcePlateInfo[i].discardSourcePlate == -1 &&
      sourcePlateInfo[i].plateBarcode != ""
    ) {
      warning.push("Discard Source Plate is a required field");
    }
  }

  if (!checkIfStampVolsAndLabwareTypesMatch(destPlateInfo)) {
    warning.push("Resolve Destination Plate Starting Vol mismatch");
  }

  if (checkForDuplicateBarcodes(sourcePlateInfo)) {
    warning.push("Duplicate Source Plate Barcodes exist");
  }

  if (checkForDuplicateBarcodes(destPlateInfo)) {
    warning.push("Duplicate Destination Plate Barcode exist");
  }

  if (deadPlateType === "") {
    warning.push("Dead Plate Type must be selected");
  }

  if (UploadASrcPlateB4YouDownloadAWorklist(sourcePlateInfo)) {
    warning.push("Upload Source Plate");
  } else if (destPlateInfo[0].labwareTypeCode === "")
    warning.push("Upload Destination Plate");

  if (topupCalc(worklistValues, methodSettings, destPlateInfo)) {
    warning.push(topupCalc(worklistValues, methodSettings, destPlateInfo));
  }

  if (topUpPresent(destPlateInfo)) {
    warning.push(topUpPresent(destPlateInfo));
  }

  if (topUpNotZero(methodSettings, destPlateInfo)) {
    warning.push(topUpNotZero(methodSettings, destPlateInfo));
  }

  if (
    currentWellSplits(worklistValues, methodSettings) &&
    methodSettings.pelletResuspensionVol === 0
  ) {
    warning.push("Cannot split wells if Pellet Resuspension Volume is 0");
  }

  return warning;
};

export const get1224TCOptionalWorklistWarnings = (
  sourcePlateInfo: any,
  destPlateInfo: any,
  worklistValues: WorklistValuesState,
  methodSettings: ITwelve24TCMethodSettings
) => {
  const warning = [];

  if (methodSettings.dissociationTime) {
    if (
      methodSettings.dissociationTime! < 5 &&
      methodSettings.dissociation === true
    ) {
      warning.push("Dissociation Time is less than 5 minutes");
    }
    if (
      methodSettings.dissociationTime! > 20 &&
      methodSettings.dissociation === true
    ) {
      warning.push("Dissociation Time is more than 20 minutes");
    }
  }

  if (methodSettings.spinParamPercent < 30) {
    warning.push("Spin Params % is less than 30");
  } else if (methodSettings.spinParamPercent > 50) {
    warning.push("Spin Params % is greater than 50");
  }
  if (methodSettings.spinTime > 8) {
    warning.push("Spin Time is greater than 8 mintues");
  }
  if (userShouldManuallySpin(worklistValues)) {
    warning.push(
      `As you have more than 9 additional destination vials, there is potential centrifuge imbalance. \
      You will need to centrifuge the rack manually.`
    );
  }
  return warning;
};

const checkIfStampVolsAndLabwareTypesMatch = (
  destPlateInfo: DestPlateInfoState[]
) => {
  if (destPlateInfo.length < 2) return true;
  const destPlateInfoCopy = [...destPlateInfo];
  const sortedByLabwareState = destPlateInfoCopy.sort((a, b) =>
    a.labwareTypeCode.toLocaleLowerCase() <
    b.labwareTypeCode.toLocaleLowerCase()
      ? -1
      : b.labwareTypeCode.toLocaleLowerCase() >
        a.labwareTypeCode.toLocaleLowerCase()
      ? 1
      : 0
  );
  for (let i = 1; i < sortedByLabwareState.length; i++) {
    if (
      sortedByLabwareState[i - 1].labwareTypeCode ===
        sortedByLabwareState[i].labwareTypeCode &&
      sortedByLabwareState[i - 1].startingVol !==
        sortedByLabwareState[i].startingVol
    )
      return false;
  }

  return true;
};

const checkVSpinRange = (methodSettings: ITwelve24TCMethodSettings) => {
  const vSpinSettingRange: {
    key: keyof ITwelve24TCMethodSettings;
    displayValue: string;
    min: number;
    max: number;
  }[] = [
    {
      key: "spinParamPercent",
      displayValue: "Spin Param(%)",
      min: 1,
      max: 100,
    },
    { key: "spinTime", displayValue: "Spin Time", min: 4, max: 30 },
    {
      key: "spinAccel",
      displayValue: "Acceleration Settings",
      min: 1,
      max: 100,
    },
    {
      key: "spinDecel",
      displayValue: "Deceleration Settings",
      min: 1,
      max: 100,
    },
  ];

  const requiredMessage = [];
  for (const setting of vSpinSettingRange) {
    const settingValue = methodSettings[setting.key] as number;
    if (settingValue < setting.min || settingValue > setting.max) {
      requiredMessage.push(
        `${setting.displayValue} must be between ${setting.min} and ${setting.max}`
      );
    }
  }

  return requiredMessage;
};

const checkForRequiredMethodSettings = (
  methodSettings: ITwelve24TCMethodSettings
) => {
  const methodSettingDisplayValues: Map<
    keyof ITwelve24TCMethodSettings,
    string
  > = new Map([["selectedSystem", "Selected System"]]);

  const requiredFieldMissing = (key: keyof ITwelve24TCMethodSettings) =>
    methodSettings[key] === undefined ||
    ["", "-1", "0"].includes(methodSettings[key]!.toString());

  const requiredMessages = [...methodSettingDisplayValues.keys()]
    .filter((key) => requiredFieldMissing(key))
    .map((key) => `${methodSettingDisplayValues.get(key)} is a required field`);
  const requiredReagentMessages =
    checkDependentRequiredReagents(methodSettings);

  return [...requiredMessages, ...requiredReagentMessages];
};

const checkDependentRequiredReagents = (
  methodSettings: ITwelve24TCMethodSettings
) => {
  type MethodSettingTuple = [
    requiredSetting: keyof ITwelve24TCMethodSettings,
    dependentSetting: keyof ITwelve24TCMethodSettings,
    fieldName: string
  ];
  const tuple: MethodSettingTuple[] = [
    [
      "washBeforeDissociation",
      "dissociationWashRGT",
      "Dissociation Wash Reagent",
    ],
  ];
  const requiredFieldMissing = (t: MethodSettingTuple) =>
    methodSettings[t[0]] && methodSettings[t[1]!]?.toString() === "0";
  const requiredReagentMessages = tuple
    .filter((t) => requiredFieldMissing(t))
    .map((t) => `${t[2]} is a required field`);

  return requiredReagentMessages;
};
